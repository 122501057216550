import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "../../../node_modules/rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
        url: this.fixUrl(request.url),
      });
    } else {
      request = request.clone({
        url: this.fixUrl(request.url),
      });
    }

    return next.handle(request);
  }

  private fixUrl(url: string) {
    // console.log('intercepted' + environment.apiUrl + url);

    return environment.apiUrl + url;
    // }
  }
}
