import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PatientsComponent } from './patients/patients.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';

import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { GeneralInfoComponent } from './general-info/general-info.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GrdFilterPipe } from '../_services/grd-filter.pipe';
import { AddEventComponent } from './add-event/add-event.component';
import { BioComponent } from './bio/bio.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { OtherFormsOfTobaccoUseComponent } from './other-forms-of-tobacco-use/other-forms-of-tobacco-use.component';



@NgModule({
  declarations: [PagesComponent, PatientsComponent, PatientInfoComponent, GeneralInfoComponent, GrdFilterPipe, AddEventComponent, BioComponent, PatientListComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgGridModule.withComponents([])
  ],
  exports:[PatientListComponent]
})
export class PagesModule { }
