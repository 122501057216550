import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabGuard implements CanActivate {


  constructor(
    private router : Router
    ){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const user = JSON.parse(localStorage.getItem('user'));
    if(user === null){
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    }
    else if (user['role'] === 'LAB_USER' ) {
      return true;
    }  else {
      localStorage.clear();
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    }

  }

}
