import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Bio, Patient } from 'src/app/_interfaces/patient';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit {


  @Input() bio : Bio;
  @Input() patient : Patient;

  ffpeBlockNumber: number;
  ffpeBlockValues = [];
  slidesNumber: number;
  slideValues = [];
  bloodNumber: number;
  bloodValues = [];
  plasmaNumber: number;
  plasmaValues = [];

  bloodTypes : string[] = ["EDTA", "STRECK"];

  constructor() { }




  ngOnInit() {
    console.log("bio component Patient detials ", this.patient);
  }


  checkPatient(){
    console.log("change patient in bio",this.patient)
  }



  public getPlasmaNumber(str: number): void {
    console.log(str);
    this.plasmaNumber = str;
  }


  public getSlidesNumber(num: number): void {
    console.log(num);
    this.slidesNumber = num;
  }

  public getFfpeNumber(str: number): void {
    console.log(str);
    this.ffpeBlockNumber = str;
  }


  getFfpeBlockValues(string, index) {
    this.ffpeBlockValues[index] = string;
    console.log("--->", this.ffpeBlockValues);
  }



  public getBloodNumber(str: number): void {
    console.log(str);
    this.bloodNumber = str;
  }

  getSlidesValues(string, index) {
    this.slideValues[index] = string;
    console.log("--->", this.slideValues);
  }

  getBloodValues(string, index) {
    this.bloodValues[index] = string;
    console.log("--->", this.bloodValues);
  }

  getPlasmaValues(string, index) {
    this.plasmaValues[index] = string;
    console.log("--->", this.plasmaValues);
  }

  arrayOne(n: number): any[] {
    if (n == null) {
      return Array(0);
    } else {
      return Array(n);
    }
  }

}
