import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegimenService {

  constructor(private httpClient: HttpClient) { }


  addRegimen(body){
    return this.httpClient.post('/user/add_regimen',body);
  }

  updateRegimen(body){
    return this.httpClient.post('/user/update_regimen',body);
  }

  regimenList(){
    return this.httpClient.get('/regimen/list');
  }

  deleteRegimen(id){
    return this.httpClient.get('/user/' + id +'/delete_regimen');
  }

}
