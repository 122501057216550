import { Hereditary, Medication } from './events';
import { Recipient } from "./register";

export interface PatientList {
  dateOfBirth: Date;
  diet: string;
  ethnicity: string;
  gender: string;
  height: number;
  maritalStatus: string;
  motherTongue: string;
  occupation: string;
  patientEmail: string;
  patientId: number;
  patientMobileNumber: string;
  patientName: string;
  patientRelation: string;
  patientUUID: string;
  religion: string;
  takeCareContact: string;
  takeCareName: string;
  weight: number;
}

export interface PatientListDto {

  isRegistrationMailSent: boolean
  patientId: number;
  patientName: string;
  familyName: string;
  patientUUID: number;
  cancerSite: string;
  lastEvent: string;
  lastEventStatus: string;
  phoneNumber: string;

  labTest: string;
  labTestDate: Date;
  labTestStatus: string;
  testPackageName: string;
}

export interface Patient {
  familyName: string;

  aadharNumber : string;
  nationalHealthId : string;

  age: number;
  houseNumber: string;
  bmi: number;
  street: string;
  locality: string;
  city: string;
  state: string;
  postalCode: string;
  country : string;



  preMenopausecycleType: string;
  preMenopausecycleDuration: number;

  religionId:number;
  languageId : number;
  dietId : number;
  occupationId: number;

  pid: number;
  address: string;
  alternateMobileNumber: string;
  dateOfBirth: Date;
  diet: string;
  ethnicity: string;
  gender: string;
  height: number;
  hospitalId: string;
  testPackageName: string;
  labTestsTaken: LabTestsTaken[];
  maritalStatus: string;
  childrens: number;
  menstruation: string;
  motherTongue: string;

  patientEmail: string;
  patientId: number;
  patientMobileNumber: string;
  patientName: string;
  patientRelation: RelationTypes;
  patientRelationId: number;
  patientUUID: string;
  religion: string;
  takeCareContact: string;
  takeCareName: string;
  weight: number;
  referralDoctorEmail: string;
  referralDoctorName : string;


  recipient: Recipient;
  recipientId: number;

  // bio bank fields
  bloodType : string;
  blood: number;
  bloodValues: string[];
  extractedDNA: string;
  extractedRNA: string;
  ffpeBlock: number;
  ffpeBlockValues: string[];
  ffpeSections: string;
  freshOrFrozenTissues: string;
  plasma: number;
  plasmaValues: string[];
  slides: number;
  slidesValues: string[];

  clientType: string;
  clientName: string;

  medicalReqDto: Medical;

  registrationEmailMessage: string;
}

export class Bio {
  // bio bank fields
  blood: number;
  bloodType : string;
  bloodValues: string[];
  extractedDNA: string;
  extractedRNA: string;
  ffpeBlock: number;
  ffpeBlockValues: string[];
  ffpeSections: string;
  freshOrFrozenTissues: string;
  plasma: number;
  plasmaValues: string[];
  slides: number;
  slidesValues: string[];
}

export interface RelationTypes {
  id: number;
  relationName: string;
}

export interface Occupation {
  id: number;
  name: string;
}

export interface CancerGrade {
  id: number;
  name: string;
}

export interface Languages {
  id: number;
  name: string;
}

export interface Religions {
  id: number;
  name: string;
}
export interface Diets {
  id: number;
  name: string;
}

export interface LabTestsTaken {
  labTestId: number;
  labTestName: string;
  labTestStatusId: number;
  labTestStatusName: string;
  testTaken: Date;
  sampleIds: string;

  testStatus: string;
  comments: string;
  labTestTakenId: number;
  patientId: number;
  testProcessId: number;

  blockIdValue: string;
  emailMessage: string;
}

export interface TestStatusLog {
  comments: string;
  createdOn: Date;
  testStatus: string;
}

export interface AddLabTestPackage {
  id: number;
  labTestIds: number[];
  name: string;
  emailMessage: string;
}

export interface LabTestPackages {
  id: number;
  labTests: LabTest[];
  name: string;
}

export interface LabTest {
  id: number;
  name: string;
}

export interface LabTestProcess {
  id: number;
  name: string;
  emailMessage: string;
}

export interface Medical {
  ageAtDiagnosis: number;
  bloodThinningMedicationSpecify: string;
  cancerCellTypeId: number;
  cancerGrade: string;
  cancerSiteId: number;
  cancerStage: string;
  cancerSubTypeId: number;
  lateralityId: number;
  cancerTypeId: number;
  dateOfConsultation: Date;
  doctorName: string;
  drinkingFrequency: string;
  drinkingSpecify: string;
  hereditaryFactor: string;
  hereditaryFactorSignificant: string;
  hospitalId: number;
  hospitalLocationId: number;
  hospitalName: string;
  hospitalPhoneNumber: string;
  isBloodThinningMedications: boolean;
  isDrinking: boolean;
  isMedicalAllergies: boolean;
  isSmoking: boolean;
  medicalAllergiesSpecify: string;
  pid: number;
  smokingFrequency: string;
  smokingSpecify: string;
  symptomsBeforeDiagnosis: string;


  drinking : string;
  smoking : string;
  cancerGradeId : number;
  otherFormsOfTobaccoUse : string;
  otherFormsOfTobaccoUseSpecify : string;
  immunosupression:string;
  imunosupressionSpecify : string;


  coMorbidityReqDtoList: CoMorbidity[];
  hereditaryReqDtoList: Hereditary[];
}

export interface Hospital {
  id: number;
  name: string;
}

export interface HospitalLocation {
  id: number;
  branchName: string;
  contactNumber: string;
  emailAddress: string;
  hospitalId: number;
  hospital: Hospital;
  address: Address;
}

export interface CancerSites {
  id: number;
  name: string;
  icdCode : string;
}

export interface CancerType {
  id: number;
  name: string;
  icdCode : string
}


export interface MorphologyType {
  id: number;
  name: string;
  icdCode: number;
}


export interface CancerSubType {
  id: number;
  name: string;
  icdCode: string;
}




export interface CancerCellTypes {
  id: number;
  name: string;
  icdCode : string;
}

export interface Disease {
  id: number;
  name: string;
}

export interface Tobacco {
  id: number;
  name: string;
}

export interface CoMorbidity {
 // comments: string[];
  diseaseName: string;
  fromYear: string;
  typeOfMedication : Medication[];
}

export interface CoMorbidityFields{
  comment : string;
  typeOfMedication : string;
}

export interface CoMorbidities {
  commnets: string;
  disease: Disease;
}

export interface Address {
  address1: string;
  address2: string;
  state: string;
  city: string;
  postalCode: string;
}
