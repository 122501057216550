import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecipientService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addRecipient(body) {
    return this.httpClient.post('/recipient/add', body);
  }

  getRecipients() {
    return this.httpClient.get('/recipient/list');
  }
}
