import { Hereditary, Laterality, Medication } from "./../../_interfaces/events";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from "src/app/_services/patient.service";
import { ReqId } from "src/app/_interfaces/reqId";
import {
  Patient,
  Medical,
  CancerCellTypes,
  CancerSites,
  CancerType,
  CoMorbidity,
  Disease,
  CancerSubType,
  CoMorbidities,
  Hospital,
  HospitalLocation,
  RelationTypes,
  CancerGrade,
  Diets,
  Occupation,
  Religions,
  Languages,
  Tobacco,
} from "src/app/_interfaces/patient";
import { MedicalService } from "src/app/_services/medical.service";
import { EventService } from "src/app/_services/event.service";
import {
  AddEvent3,
  EventTest,
  TypeOfTest,
  AddEvent1,
  DiagnosticTest,
  Tumor,
  Marker,
  BioChemicalMarker,
  Events,
  Surgery,
  TestTumor,
  AddEvent4,
} from "src/app/_interfaces/events";
import { element } from "protractor";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RegimenService } from "src/app/_services/regimen.service";
import {
  AddCycle,
  AddDosage,
  AddRadiation,
  Drugs,
  ImmunoCycles,
  ImmunoDrugDosageList,
  ImmunoDrugs,
  RadiationTypes,
  RegimenList,
  SurgeryAdd,
  TreatementChemotherapy,
  TreatementHormonal,
  TreatementImmunotherapy,
  TreatementRadiation,
  TreatementSupportiveCare,
  TreatementSurgery,
} from "src/app/_interfaces/treatement";
import { DetailsType3, EventType3 } from "src/app/_interfaces/summary";
import { DatePipe } from "@angular/common";
import { CancerGradeService } from "src/app/_services/cancer-grade-service";
import { TobaccoService } from "src/app/_services/tobacco.service";
import { typeofExpr } from "@angular/compiler/src/output/output_ast";
import { TypeOfMedication } from "src/app/constants/type-of-medication";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-patient-info",
  templateUrl: "./patient-info.component.html",
  styleUrls: ["./patient-info.component.css"],
  providers: [DatePipe],
})
export class PatientInfoComponent implements OnInit {
  exportSpinner = false;

  occupations: CancerGrade[];
  occupationsList: Occupation[];
  religionsB: Religions[];
  languagesB: Languages[];

  pid: number;
  reqId = {} as ReqId;
  patient = {} as Patient;
  medicalInfo = {} as Medical;
  relationTypes: RelationTypes[];

  alcoholOptions = ["Current", "Past", "Never", "Unknown"];
  smokingOptions = [
    "Current every day smoker",
    "Current some day smoker",
    "Former  smoker",
    "Never smoker",
    "Smoker",
    "current status unknown",
    "Unknown if ever smoked",
    "Current Light tobacco smoker",
    "Current Heavy tobacco smoker",
  ];

  maritalStatus: string[] = [
    "Divorced",
    "MARRIED",
    "Unmarried",
    "Widowed",
    "Unknown",
    "Single",
  ];

  eventId: number;

  event3 = {} as AddEvent3;
  event1 = {} as AddEvent1;
  event4 = {} as AddEvent4;

  cancerSite = {} as CancerSites;

  treatementChemotherapy = {} as TreatementChemotherapy;
  treatementChemotherapySpinner = false;

  treatementSurgery = {} as TreatementSurgery;
  treatementSurgerySpinner = false;

  treatementRadiation = {} as TreatementRadiation;
  treatementRadiationSpinner = false;

  treatementSupportiveCare = {} as TreatementSupportiveCare;
  treatementSupportiveCareSpinner = false;

  treatementImmunotherapy = {} as TreatementImmunotherapy;
  treatementImmunotherapySpinner = false;

  treatementHormonal = {} as TreatementHormonal;
  treatementHormonalSpinner = false;

  add_dosage = {} as AddDosage;
  editdosage_toggle: boolean;
  editdosage_index: number;
  editcycle_index: number;
  editImmunoCycle_index: number;
  editdosage_date = true;

  add_immuno_dosage = {} as ImmunoDrugDosageList;
  edit_immuno_dosage_toggle: boolean;
  edit_immuno_dosage_index: number;
  edit_immuno_cycle_index: number;
  edit_immuno_dosage_date = true;

  add_cycle = {} as AddCycle;

  add_surgery = {} as SurgeryAdd;
  editsurgery_toggle: boolean;
  editsurgery_index: number;

  add_radiation = {} as AddRadiation;
  editRadiation_toggle: boolean;
  editRadiation_index: number;

  add_marker = {} as Marker;
  editmarker_toggle: boolean;
  editmarker_index: number;

  add_diagnostic = {} as EventTest;
  editdiagnostic_toggle: boolean;
  editdiagnostic_index: number;

  add_tumor = {} as Tumor;
  editTumor_toggle: boolean;
  editTumor_index: number;

  add_coMorbidity = {} as CoMorbidity;
  add_Hereditary = {} as Hereditary;
  typeOfMedications = new TypeOfMedication().typeOfMedication;
  surgeryDropdownSettings = {};

  editCoMorbidity_toggle: boolean;
  editCoMorbidity_index: number;

  editHereditary_toggle: boolean;
  editHereditary_index: number;

  hospitals: Hospital[];
  hospitalLocations: HospitalLocation[];
  cancerCellTypes: CancerCellTypes[];
  cancerSites: CancerSites[];
  cancerTypes: CancerType[];
  cancerSubTypes: CancerSubType[];
  lateralities: Laterality[];
  diseases: Disease[];
  tobaccos: Tobacco[];
  typeOfTets: TypeOfTest[];
  diagnosticTestsArr: DiagnosticTest[];
  bioChemicalMarkerArr: BioChemicalMarker[];
  surgeries: Surgery[];
  radiationTypes: RadiationTypes[];
  immunoDrugs: ImmunoDrugs[];
  events: Events[];
  regimenList: RegimenList[];
  drugs: Drugs[];
  htDrugs: Drugs[];
  cancerSiteICDCode: CancerSites;

  medicalSpinner = false;
  option1 = false;
  option2 = false;
  option3 = false;
  option4 = false;
  // cancerSiteName: string;
  cancerSiteId: number;

  OptionChemotheropy = false;
  OptionSurgery = false;
  OptionRadiation = false;
  OptionSupportive_Care = false;
  OptionImmunotherapy = false;
  OptionHormonal_Therapy = false;

  hormonalDrugTherapy = false;
  hormonalSurgery = false;
  hormonalRadiation = false;

  optionValue: any;

  statusMsg: string;

  firstChemoDate: Date;
  noc: number;
  cycleInterval: number;
  addTypeOfMedications: string[] = [];
  addTypeOfMedicationsComments: string[] = [];

  addTypeOfMedicationsCounter: number = 0;

  showDetails = true;
  showDetail() {
    this.showDetails = false;
    console.log("show details");
  }
  hideDetail() {
    this.showDetails = true;
  }

  addCoMorbidities: {
    coMorbidities: CoMorbidity[];
  };

  addHereditaries: {
    hereditaries: Hereditary[];
  };

  addTumors: {
    tumors: Tumor[];
  };

  addMarkers: {
    markers: Marker[];
  };

  addDiagnosticTest: {
    diagnosticTests: EventTest[];
  };

  addDosages: {
    dosages: AddDosage[];
  };

  addImmunoDosages: {
    immunoDosages: ImmunoDrugDosageList[];
  };

  addCycles: {
    cycles: AddCycle[];
  };

  addSurgeries: {
    surgeriesAdd: SurgeryAdd[];
  };

  addRadiations: {
    radiations: AddRadiation[];
  };

  addEventTests: {
    eventTests: EventTest[];
  };

  addTestTumors: {
    testTumors: TestTumor[];
  };

  addMedications: {
    medication: Medication[];
  };

  addDrugForm = false;
  cycleDate: Date;

  addCycleForm() {
    this.addDrugForm = true;
    this.addDosageManually();
  }

  addDosageManually(): void {
    this.addDosages.dosages.push({
      dosage: 0,
      drugId: 0,
      drugName: "",
      date: new Date(),
    });
    console.log(this.addDosages.dosages);
  }

  addImmunoDosageManually(): void {
    this.addImmunoDosages.immunoDosages.push({
      dosage: 0,
      drugId: 0,
      drugName: "",
      date: new Date(),
      otherSpecify: "",
    });
    console.log(this.addImmunoDosages.immunoDosages);
  }

  deleteCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementChemotherapy.cycles.splice(cycleIndex, 1);
  }

  deleteImmuCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementImmunotherapy.immunoCycles.splice(cycleIndex, 1);
  }

  getOccupations() {
    this.cancerGradeService.getCancerGrades().subscribe((resopose) => {
      this.occupations = resopose["payLoad"];
      console.log(resopose);
    });
  }

  editCycleModal = false;
  editCycle(edit_cycle: AddCycle, cycleIndex: number) {
    this.editCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editcycle_index = cycleIndex;
    this.add_cycle = { ...edit_cycle };
    this.addDosages.dosages = this.add_cycle.drugDosages;
    console.log(this.addDosages.dosages);
  }

  add_immuno_cycle = {} as ImmunoCycles;

  editImmunoCycleModal = false;
  editImmunoCycle(edit_cycle: ImmunoCycles, cycleIndex: number) {
    this.editImmunoCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editImmunoCycle_index = cycleIndex;
    this.add_immuno_cycle = { ...edit_cycle };
    this.addImmunoDosages.immunoDosages =
      this.add_immuno_cycle.immunoDrugDosages;
    console.log(this.addImmunoDosages.immunoDosages);
  }

  drugsListSelect = [];
  drugsMultiple: Drugs[];
  drugsDropdownSettings = {};
  isSetCycleButton = false;
  selectedDrugs: Drugs[];
  selectedMultipleDropdownDrugs: Drugs[];

  getDrugsMultiple() {
    this.eventService.getDrugs().subscribe((response) => {
      this.drugsMultiple = response["payLoad"];
      console.log("drugs types", this.drugsMultiple);
    });

    this.drugsDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  selectedDrugsMultiple() {
    console.log("his.drugsListSelect", this.drugsListSelect);
    this.selectedMultipleDropdownDrugs = this.drugsListSelect;
    console.log(
      "this.selectedMultipleDropdownDrugs",
      this.selectedMultipleDropdownDrugs
    );
    this.isSetCycleButton = true;
  }

  setCycle() {
    console.log(this.selectedMultipleDropdownDrugs, this.selectedRegimenDrugs);
    if (this.selectedMultipleDropdownDrugs == undefined) {
      this.selectedMultipleDropdownDrugs = [];
    }
    if (this.selectedRegimenDrugs == undefined) {
      this.selectedRegimenDrugs = [];
    }
    this.selectedDrugs = this.selectedMultipleDropdownDrugs.concat(
      this.selectedRegimenDrugs
    );
    console.log("selectedDrugs", this.selectedDrugs);
  }

  getPatientRelations() {
    this.patientService.getPatientRelations().subscribe((response) => {
      this.relationTypes = response["payLoad"];
      console.log("relationTypes", this.relationTypes);
    });
  }

  removeDosage(index) {
    console.log("index", index);
    this.addDosages.dosages.splice(index, 1);
  }

  settedCycle() {
    console.log("this.addDosages.dosages", this.addDosages.dosages);
    if (this.editCycleModal) {
      console.log("this.editCycleModal", this.editCycleModal);
      let cycle = {} as AddCycle;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.allDrugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      this.treatementChemotherapy.cycles[this.editcycle_index] = cycle;
      console.log(this.treatementChemotherapy.cycles);
    }
    // this.addDosages.dosages = [];
  }

  removeImmunoDosage(i) {
    this.addImmunoDosages.immunoDosages.splice(i, 1);
  }
  onItemSelect(item) {
    console.log(this.add_coMorbidity.typeOfMedication);

    // this.addTypeOfMedicationsCounter++;
    // this.addTypeOfMedications.push(item);
    // console.log(this.addTypeOfMedications , this.addTypeOfMedicationsCounter);
  }

  onFilterChange(event) {
    console.log("onfilterchange ", this.add_coMorbidity.typeOfMedication);
  }

  onDeselect(event) {
    this.addTypeOfMedications.filter((item) => item !== event);
    console.log(this.addTypeOfMedications);
    console.log(" onDeselect called ", event);
  }

  ondropdown(event) {
    console.log(event);
    this.addTypeOfMedicationsCounter = this.addTypeOfMedications.length;
    console.log(
      " on dropdown close : count " +
        this.addTypeOfMedicationsCounter +
        " , medications" +
        this.addTypeOfMedications
    );
  }

  settedImmunoCycle() {
    console.log(
      "this.addImmunoDosages.immunoDosages",
      this.addImmunoDosages.immunoDosages
    );
    if (this.editImmunoCycleModal) {
      console.log("this.editCycleModal", this.editImmunoCycleModal);
      let cycle = {} as ImmunoCycles;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.immunoDrugDosages = [];
      for (let i = 0; i < this.addImmunoDosages.immunoDosages.length; i++) {
        let dosage = {} as ImmunoDrugDosageList;
        let drugById = this.immunoDrugs.filter(
          (drugObj) =>
            drugObj.name === this.addImmunoDosages.immunoDosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addImmunoDosages.immunoDosages[i].drugName;
        dosage.dosage = this.addImmunoDosages.immunoDosages[i].dosage;
        dosage.date = this.addImmunoDosages.immunoDosages[i].date;
        cycle.immunoDrugDosages.push(dosage);
      }
      this.treatementImmunotherapy.immunoCycles[this.editImmunoCycle_index] =
        cycle;
      console.log(this.treatementImmunotherapy.immunoCycles);
    }
  }

  viewCycle = false;
  generateCycles() {
    this.viewCycle = true;
    // console.log("this drugs",this.drugs);
    this.treatementChemotherapy.cycles = [];
    console.log(
      "start date: " +
        this.firstChemoDate +
        ", interval:" +
        this.cycleInterval +
        ", noc:" +
        this.noc
    );
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as AddCycle;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.allDrugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.addDosages.dosages[i].drugId;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = new Date(
          new Date(this.firstChemoDate).getTime() +
            index * this.cycleInterval * (1000 * 60 * 60 * 24)
        );
        console.log(dosage);
        // this.addDosages.dosages[i].date = new Date(new Date(this.firstChemoDate).getTime() + ((index) * this.cycleInterval * (1000 * 60 * 60 * 24)));
        cycle.drugDosages.push(dosage);
      }
      this.treatementChemotherapy.cycles.push(cycle);
      console.log(
        "this.treatementChemotherapy.cycles",
        this.treatementChemotherapy.cycles
      );
    }
    // this.addDosages.dosages = [];
  }

  addDosage() {
    if (!this.editdosage_toggle) {
      // this.treatementChemotherapy.cycles = [];
      let cycle = {} as AddCycle;
      // cycle.cycleDate = this.cycleDate;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.drugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      console.log("cycle", cycle);
      this.treatementChemotherapy.cycles.push(cycle);
      console.log(this.treatementChemotherapy.cycles);
      this.addDosages.dosages = [];
    } else {
      let drugById = this.drugs.filter(
        (drugObj) => drugObj.name === this.add_dosage.drugName
      );
      this.add_dosage.drugId = drugById[0].id;
      this.treatementChemotherapy.cycles[this.editcycle_index].drugDosages[
        this.editdosage_index
      ] = this.add_dosage;
      this.editdosage_toggle = false;
      this.add_dosage = {} as AddDosage;
      console.log(this.treatementChemotherapy.cycles);
    }
  }

  editDosage(edit_dosage: AddDosage, cycleIndex: number, dosageIndex: number) {
    this.addDrugForm = false;
    this.editdosage_index = dosageIndex;
    this.editcycle_index = cycleIndex;
    this.editdosage_toggle = true;
    this.editdosage_date = false;
    this.add_dosage = { ...edit_dosage };
  }

  deleteDosage(cycleIndex, dosageIndex) {
    console.log(cycleIndex, dosageIndex);
    this.treatementChemotherapy.cycles[cycleIndex].drugDosages.splice(
      dosageIndex,
      1
    );
  }
  changeAddDosageDate($event, i) {
    console.log($event.target.value, i);
    this.addDosages.dosages[i].date = new Date($event.target.value);
  }
  changeAddDosageDateImmuno($event, i) {
    console.log($event.target.value, i);
    this.addImmunoDosages.immunoDosages[i].date = new Date($event.target.value);
  }
  changeAddDosageDateHt($event, i) {
    console.log($event.target.value, i);
    this.addDosages.dosages[i].date = new Date($event.target.value);
  }

  years: number[] = [];

  productForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cancerGradeService: CancerGradeService,
    private routerAct: ActivatedRoute,
    private patientService: PatientService,
    private medicalService: MedicalService,
    private router: Router,
    private eventService: EventService,
    private regimen: RegimenService,
    private snackBar: MatSnackBar,
    private tobaccoService: TobaccoService,
    private datePipe: DatePipe
  ) {
    this.productForm = this.fb.group({
      name: "",
      quantities: this.fb.array([]),
    });

    for (let year = new Date().getFullYear(); year >= 1900; year--) {
      this.years.push(year);
    }

    this.editCoMorbidity_toggle = false;
    this.editCoMorbidity_toggle = false;
    this.editmarker_toggle = false;
    this.editdiagnostic_toggle = false;
    this.editTumor_toggle = false;
  }

  showSuccess() {
    this.snackBar.open(this.statusMsg, "dismiss", {
      duration: 2000,
      verticalPosition: "top",
    });
  }

  isSuperAdmin = false;

  ngOnInit() {
    let appUser = JSON.parse(localStorage.getItem("user"));
    if (appUser["role"] === "SUPER_ADMIN") {
      this.isSuperAdmin = true;
    }

    this.pid = +this.routerAct.snapshot.params["id"];
    console.log("pid", this.pid);
    if (this.pid != null) {
      this.getPatient();
    }

    this.getHospitals();
    this.getEvents();
    this.getCancerCellTypes();
    this.getCancerSites();
    this.getDiseases();
    this.getLateralites();
    this.getOccupations();
    this.getDiets();
    this.getOccupationsList();
    this.getLanguages();
    this.getReligions();
    this.getTobaccoTypes();

    this.addCoMorbidities = {
      coMorbidities: [],
    };

    this.addHereditaries = {
      hereditaries: [],
    };

    this.addMarkers = {
      markers: [],
    };

    this.addDiagnosticTest = {
      diagnosticTests: [],
    };

    this.addDosages = {
      dosages: [],
    };

    this.addImmunoDosages = {
      immunoDosages: [],
    };

    this.addCycles = {
      cycles: [],
    };

    this.addSurgeries = {
      surgeriesAdd: [],
    };

    this.addRadiations = {
      radiations: [],
    };
    this.addTumors = {
      tumors: [],
    };

    this.addEventTests = {
      eventTests: [],
    };

    this.addTestTumors = {
      testTumors: [],
    };

    this.addMedications = {
      medication: [],
    };

    this.getPatientRelations();
    
  }
  getTobaccoTypes() {
    this.tobaccoService.getTobaccos().subscribe((response) => {
      this.tobaccos = response["payLoad"];
      console.log("tobaccos", this.tobaccos);
    });
  }

  addCoMorbidity() {
    if (!this.editCoMorbidity_toggle) {

     if(this.addMedications.medication.length > 0)
     {
       this.add_coMorbidity.typeOfMedication = this.addMedications.medication;
       this.addMedications.medication = [];
     }
      console.log("add_comorbidity", this.add_coMorbidity);


      this.addCoMorbidities.coMorbidities.push(this.add_coMorbidity);
      this.add_coMorbidity = {} as CoMorbidity;
    } else {

      this.addCoMorbidities.coMorbidities[this.editCoMorbidity_index] = this.add_coMorbidity;
      this.addMedications.medication = this.add_coMorbidity.typeOfMedication
      this.editCoMorbidity_toggle = false;
      this.add_coMorbidity = {} as CoMorbidity;

    }
    this.medicalInfo.coMorbidityReqDtoList =
      this.addCoMorbidities.coMorbidities;
  }

  editCoMorbidity(edit_coMorbidity: CoMorbidity, index: number) {
    console.log(edit_coMorbidity,index);
    this.editCoMorbidity_index = index;
    this.editCoMorbidity_toggle = true;
    this.add_coMorbidity = { ...edit_coMorbidity };
    this.addMedications.medication = this.add_coMorbidity.typeOfMedication;
  }

  deleteCoMorbidity(index) {
    console.log(index);
    this.addCoMorbidities.coMorbidities.splice(index, 1);
    this.medicalInfo.coMorbidityReqDtoList =
      this.addCoMorbidities.coMorbidities;
  }

  addHereditary() {
    if (!this.editHereditary_toggle) {
      console.log("add_hereditary", this.add_Hereditary);

      this.addHereditaries.hereditaries.push(this.add_Hereditary);
      this.add_Hereditary = {} as Hereditary;
    } else {
      this.addHereditaries.hereditaries[this.editHereditary_index] =
        this.add_Hereditary;
      this.editHereditary_toggle = false;
      this.add_Hereditary = {} as Hereditary;
    }
    this.medicalInfo.hereditaryReqDtoList = this.addHereditaries.hereditaries;
  }

  editHereditary(edit_hereditary: Hereditary, index: number) {
    console.log(index);
    this.editHereditary_index = index;
    this.editHereditary_toggle = true;
    this.add_Hereditary = { ...edit_hereditary };
  }

  deleteHereditary(index) {
    console.log(index);
    this.addHereditaries.hereditaries.splice(index, 1);
    this.medicalInfo.hereditaryReqDtoList = this.addHereditaries.hereditaries;
  }

  addMedication(): void {
    this.addMedications.medication.push({
      typeofMedication: "",
      drug: "",
    });

    console.log(this.addMedications.medication);

  }

  addTestTumor(): void {
    this.addTestTumors.testTumors.push({
      tumorLength: "",
      tumorWidth: "",
      tumorVolume: "",
    });
    console.log(this.addTestTumors.testTumors);
  }


  removeMedication(i) {
    console.log("remove Medication", i);
    this.add_coMorbidity.typeOfMedication.splice(i, 1);
  }

  removeTestTumor(i) {
    console.log("remove tumor", i);
    this.addTestTumors.testTumors.splice(i, 1);
  }

  addEventTest() {
    if (!this.editdiagnostic_toggle) {
      this.add_diagnostic.testTumors = this.addTestTumors.testTumors;
      this.addTestTumors.testTumors = [];
      this.addDiagnosticTest.diagnosticTests.push(this.add_diagnostic);
      console.log(this.add_diagnostic);
      this.add_diagnostic = {} as EventTest;
    } else {
      this.add_diagnostic.testTumors = this.addTestTumors.testTumors;
      this.addTestTumors.testTumors = [];
      this.addDiagnosticTest.diagnosticTests[this.editdiagnostic_index] =
        this.add_diagnostic;
      console.log("edit ", this.add_diagnostic);
      this.editdiagnostic_toggle = false;
      this.add_diagnostic = {} as EventTest;
    }
    this.event3.tests = this.addDiagnosticTest.diagnosticTests;
    console.log(this.event3.tests);
  }

  deleteEventTest(index) {
    console.log(index);
    this.addDiagnosticTest.diagnosticTests.splice(index, 1);
    this.event3.tests = this.addDiagnosticTest.diagnosticTests;
  }

  ediEventTest(edit_test: EventTest, index: number) {
    console.log(index);
    this.editdiagnostic_index = index;
    this.editdiagnostic_toggle = true;
    this.add_diagnostic = { ...edit_test };
    // this.add_diagnostic.testTumorDtoList = { ...edit_test.testTumorDtoList };
    this.addTestTumors.testTumors = this.add_diagnostic.testTumors;
    console.log("edit button clicked", this.add_diagnostic);
  }

  addMarker() {
    if (!this.editmarker_toggle) {
      this.addMarkers.markers.push(this.add_marker);
      console.log(this.add_marker);
      this.add_marker = {} as Marker;
    } else {
      this.addMarkers.markers[this.editmarker_index] = this.add_marker;
      this.editmarker_toggle = false;
      this.add_marker = {} as Marker;
    }
    this.event1.markers = this.addMarkers.markers;
  }

  deleteMarker(index) {
    console.log(index);
    this.addMarkers.markers.splice(index, 1);
    this.event1.markers = this.addMarkers.markers;
  }

  showeditMarker(edit_marker: Marker, index: number) {
    console.log(index);
    this.editmarker_index = index;
    this.editmarker_toggle = true;
    this.add_marker = { ...edit_marker };
  }

  closeSurgeryModal() {
    this.add_surgery = {} as SurgeryAdd;
  }

  selectedSurgeries = [];
  surgeryDate: Date;

  addSurgery() {
    console.log(this.selectedSurgeries);
    if (!this.editsurgery_toggle) {
      console.log("add_surgery", this.add_surgery);
      for (let i = 0; i < this.selectedSurgeries.length; i++) {
        this.add_surgery.surgeryId = this.selectedSurgeries[i].id;
        this.add_surgery.surgeryName = this.selectedSurgeries[i].name;
        this.add_surgery.date = this.surgeryDate;
        this.addSurgeries.surgeriesAdd.push(this.add_surgery);
        this.add_surgery = {} as SurgeryAdd;
        // statements to be executed repeatedly
      }
      // let surgeryById = this.surgeries.filter(surgeryObj => surgeryObj.name === this.add_surgery.surgeryName);
      // this.add_surgery.surgeryId = surgeryById[0].id;
      // this.addSurgeries.surgeriesAdd.push(this.add_surgery);
      this.add_surgery = {} as SurgeryAdd;
      this.selectedSurgeries = [];
    } else {
      console.log("edit surgery", this.add_surgery);
      let surgeryById = this.surgeries.filter(
        (surgeryObj) => surgeryObj.name === this.add_surgery.surgeryName
      );
      this.add_surgery.surgeryId = surgeryById[0].id;
      this.addSurgeries.surgeriesAdd[this.editsurgery_index] = this.add_surgery;
      this.add_surgery.date = this.surgeryDate;
      this.editsurgery_toggle = false;
      this.add_surgery = {} as SurgeryAdd;
    }
    if (this.hormonalSurgery) {
      this.treatementHormonal.surgeries = this.addSurgeries.surgeriesAdd;
    } else {
      this.treatementSurgery.surgeries = this.addSurgeries.surgeriesAdd;
    }
  }

  editSurgery(edit_surgery: SurgeryAdd, index: number) {
    console.log(index);
    this.editsurgery_index = index;
    this.editsurgery_toggle = true;
    this.add_surgery = { ...edit_surgery };
  }

  deleteSurgery(index) {
    console.log(index);
    this.addSurgeries.surgeriesAdd.splice(index, 1);
    this.treatementSurgery.surgeries = this.addSurgeries.surgeriesAdd;
  }

  addRadiation() {
    if (!this.editRadiation_toggle) {
      console.log("add_radiation", this.add_radiation);
      let radiationById = this.radiationTypes.filter(
        (radiationObj) =>
          radiationObj.name === this.add_radiation.radiationTypeName
      );
      this.add_radiation.radiationTypeId = radiationById[0].id;
      this.addRadiations.radiations.push(this.add_radiation);
      this.add_radiation = {} as AddRadiation;
    } else {
      console.log("edit surgery", this.add_radiation);
      let radiationById = this.radiationTypes.filter(
        (radiationObj) =>
          radiationObj.name === this.add_radiation.radiationTypeName
      );
      this.add_radiation.radiationTypeId = radiationById[0].id;
      this.addRadiations.radiations[this.editRadiation_index] =
        this.add_radiation;
      this.editRadiation_toggle = false;
      this.add_radiation = {} as AddRadiation;
    }
    if (this.hormonalRadiation) {
      this.treatementHormonal.radiations = this.addRadiations.radiations;
    } else {
      this.treatementRadiation.radiations = this.addRadiations.radiations;
    }
  }

  editRadiation(edit_radiation: AddRadiation, index: number) {
    console.log(index);
    this.editRadiation_index = index;
    this.editRadiation_toggle = true;
    this.add_radiation = { ...edit_radiation };
  }

  deleteRadiation(index) {
    console.log(index);
    this.addRadiations.radiations.splice(index, 1);
    this.treatementRadiation.radiations = this.addRadiations.radiations;
  }

  addTumor() {
    if (!this.editTumor_toggle) {
      this.addTumors.tumors.push(this.add_tumor);
      console.log(this.add_tumor);
      this.add_tumor = {} as Tumor;
    } else {
      this.addTumors.tumors[this.editTumor_index] = this.add_tumor;
      this.editTumor_toggle = false;
      this.add_tumor = {} as Tumor;
    }
    this.event1.tumors = this.addTumors.tumors;
  }

  editTumor(edit_tumor: Tumor, index: number) {
    console.log(index);
    this.editTumor_index = index;
    this.editTumor_toggle = true;
    this.add_tumor = { ...edit_tumor };
  }

  removeTumor(i) {
    console.log("remove tumor", i);
    this.addTumors.tumors.splice(i, 1);
    this.event1.tumors = this.addTumors.tumors;
  }

  removeCoMorbidity(i) {
    this.addCoMorbidities.coMorbidities.splice(i, 1);
  }

  onSubmit() {}

  relationType: string;
  dietsB: Diets[];

  getDiets() {
    this.patientService.getDiets().subscribe((response) => {
      this.dietsB = response["payLoad"];
      console.log("Diets ", this.dietsB);
    });
  }

  getPatient() {
    this.reqId.id = this.pid;
    this.patientService.getPatient(this.reqId).subscribe(
      (response) => {
        console.log("complete response ", response);

        this.patient = response["payLoad"];

        if (response["object"] != null){

        this.patient.ffpeSections = response["object"]["ffpeSections"];
        this.patient.freshOrFrozenTissues = response["object"]["freshOrFrozenTissues"];
        this.patient.ffpeBlock = response["object"]["ffpeBlock"];
        this.patient.ffpeBlockValues = response["object"]["ffpeBlockValues"];
        this.patient.bloodType = response["object"]["bloodType"];
        this.patient.blood = response["object"]["blood"];
        this.patient.bloodValues = response["object"]["bloodValues"];
        this.patient.plasma = response["object"]["plasma"];
        this.patient.plasmaValues = response["object"]["plasmaValues"];
        this.patient.extractedRNA = response["object"]["extractedRNA"];
        this.patient.extractedDNA = response["object"]["extractedDNA"];
        this.patient.slides = response["object"]["slides"];
        this.patient.slidesValues = response["object"]["slidesValues"];
        
      }

        if (response["payLoad"]["patientRelation"] != null) {
          this.relationType =
            response["payLoad"]["patientRelation"]["relationName"];
        }

        if (response["payLoad"]["aadharNumber"] != null) {
          this.patient.aadharNumber = response["payLoad"]["aadharNumber"];
        }

        if (response["payLoad"]["nationalHealthId"] != null) {
          this.patient.nationalHealthId =
            response["payLoad"]["nationalHealthId"];
        }

        if (response["payLoad"]["maritalStatus"] != null) {
          this.patient.maritalStatus = response["payLoad"]["maritalStatus"];
        }





        if (response["payLoad"]["diet"] != null ) {
          if( response["payLoad"]["diet"]["id"] != null)
          this.patient.dietId = response["payLoad"]["diet"]["id"];
        }

        if (response["payLoad"]["occupation"] != null) {
          if(response["payLoad"]["occupation"]["id"] != null)
          this.patient.occupationId = response["payLoad"]["occupation"]["id"];
        }

        if (response["payLoad"]["religion"] != null) {
          this.patient.religionId = response["payLoad"]["religion"]["id"];
        }
        if (response["payLoad"]["language"]!= null) {
          this.patient.languageId = response["payLoad"]["language"]["id"];
        }



        if (this.patient["medical"] != null) {

          console.log("medical info", response["payLoad"]["medical"]);

          this.medicalInfo.ageAtDiagnosis =
            response["payLoad"]["medical"]["ageAtDiagnosis"];
          this.medicalInfo.bloodThinningMedicationSpecify =
            response["payLoad"]["medical"]["bloodThinningMedicationSpecify"];
          this.medicalInfo.cancerGrade =
            response["payLoad"]["medical"]["cancerGrade"];
          this.medicalInfo.cancerStage =
            response["payLoad"]["medical"]["cancerStage"];
          this.medicalInfo.dateOfConsultation =
            response["payLoad"]["medical"]["dateOfConsultation"];
          this.medicalInfo.doctorName =
            response["payLoad"]["medical"]["doctorName"];
          this.medicalInfo.drinkingFrequency =
            response["payLoad"]["medical"]["drinkingFrequency"];
          this.medicalInfo.smokingFrequency =
            response["payLoad"]["medical"]["smokingFrequency"];
          this.medicalInfo.drinkingSpecify =
            response["payLoad"]["medical"]["drinkingSpecify"];
          this.medicalInfo.hereditaryFactor =
            response["payLoad"]["medical"]["hereditaryFactor"];
          this.medicalInfo.hereditaryFactorSignificant =
            response["payLoad"]["medical"]["hereditaryFactorSignificant"];
            if( response["payLoad"]["medical"]["hospital"] != null){
          this.medicalInfo.hospitalId = response["payLoad"]["medical"]["hospital"]["id"];
          this.getHospitalLocations(this.medicalInfo.hospitalId);
          this.medicalInfo.hospitalLocationId = response["payLoad"]["medical"]["hospitalLocation"]["id"];
        }

          this.medicalInfo.isBloodThinningMedications =
            response["payLoad"]["medical"]["isBloodThinningMedications"];
          this.medicalInfo.isDrinking =
            response["payLoad"]["medical"]["isDrinking"];
          this.medicalInfo.isMedicalAllergies =
            response["payLoad"]["medical"]["isMedicalAllergies"];

          this.medicalInfo.drinking =
            response["payLoad"]["medical"]["drinking"];
          this.medicalInfo.smoking = response["payLoad"]["medical"]["smoking"];

          if (response["payLoad"]["medical"]["cancerGradeId"] != null) {
          this.medicalInfo.cancerGradeId =
            response["payLoad"]["medical"]["cancerGradeId"]["id"];
          }
          this.medicalInfo.otherFormsOfTobaccoUse =
            response["payLoad"]["medical"]["otherFormsOfTobaccoUse"];
          this.medicalInfo.immunosupression =
            response["payLoad"]["medical"]["immunosupression"];
          this.medicalInfo.imunosupressionSpecify =
            response["payLoad"]["medical"]["imunosupressionSpecify"];
          this.medicalInfo.hospitalPhoneNumber =
            response["payLoad"]["medical"]["hospitalLocation"]["contactNumber"];

          this.medicalInfo.isSmoking =
            response["payLoad"]["medical"]["isSmoking"];

          this.medicalInfo.medicalAllergiesSpecify =
            response["payLoad"]["medical"]["medicalAllergiesSpecify"];
          this.medicalInfo.smokingSpecify =
            response["payLoad"]["medical"]["smokingSpecify"];
          this.medicalInfo.symptomsBeforeDiagnosis =
            response["payLoad"]["medical"]["symptomsBeforeDiagnosis"];
            if (response["payLoad"]["medical"]["cancerSite"] != null) {
          this.medicalInfo.cancerSiteId =
            response["payLoad"]["medical"]["cancerSite"]["id"];
            }
            if (response["payLoad"]["medical"]["cancerType"] != null) {
          this.medicalInfo.cancerTypeId =
            response["payLoad"]["medical"]["cancerType"]["id"];
            }
            if (response["payLoad"]["medical"]["cancerSubType"] != null) {
              this.medicalInfo.cancerSubTypeId = response["payLoad"]["medical"]["cancerSubType"]["id"];
            }

          if (response["payLoad"]["medical"]["laterality"] != null) {
            this.medicalInfo.lateralityId  =
              response["payLoad"]["medical"]["laterality"]["id"];
          }

          if (response["payLoad"]["medical"]["cancerCellType"] != null) {
            this.medicalInfo.cancerCellTypeId =
              response["payLoad"]["medical"]["cancerCellType"]["id"];
          }

          //
          
          if (response["payLoad"]["medical"]["cancerType"] != null) {
          this.cancerTypes =
            response["payLoad"]["medical"]["cancerSite"]["cancerTypeList"];
          this.cancerSubTypes = response['payLoad']['medical']['cancerType']['cancerSubTypes'];
          }

          this.medicalInfo.coMorbidityReqDtoList =
            response["payLoad"]["medical"]["coMorbidities"];



            console.log(this.medicalInfo.coMorbidityReqDtoList);


          this.medicalInfo.hereditaryReqDtoList =
            response["payLoad"]["medical"]["hereditaries"];

          this.addHereditaries.hereditaries =
            this.medicalInfo.hereditaryReqDtoList;




        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getEvents() {
    this.eventService.getEvents(this.pid).subscribe((response) => {
      console.log("events", response);
      this.events = response["payLoad"]["events"];
      console.log("this.events", this.events);
    });
  }

  addMedical() {
    if (this.patient["medical"] == null) {
      console.log("add medical if block is called");

      this.medicalSpinner = true;
      this.medicalInfo.pid = this.pid;
      this.medicalInfo.hospitalPhoneNumber = this.hospitalPhoneNumber;
      console.log("medical info", this.medicalInfo);



      this.medicalService.addMedicalInfo(this.medicalInfo).subscribe(
        (response) => {
          console.log("add medical response", response);
          this.router.navigate(["patients"]);
          this.statusMsg = response["message"];
          this.showSuccess();
          this.medicalSpinner = false;
        },
        (error) => {
          console.log("error", error);
          this.statusMsg = error.error["message"];
          this.showSuccess();
          this.medicalSpinner = false;
        }
      );
    } else {
      console.log("add medical else block is called");

      this.medicalSpinner = true;
      this.medicalInfo.pid = this.pid;
      console.log("medical info", this.medicalInfo);



      this.medicalService.updateMedicalInfo(this.medicalInfo).subscribe(
        (response) => {
          console.log("add medical response", response);
          this.router.navigate(["patients"]);
          this.statusMsg = response["message"];
          this.showSuccess();
          this.medicalSpinner = false;
        },
        (error) => {
          console.log("error", error);
          this.statusMsg = error.error["message"];
          this.showSuccess();
          this.medicalSpinner = false;
        }
      );
    }
  }

  isCtnmMValue = false;
  public cTNMStatusModelChange(str: string): void {
    console.log(str);
    this.isCtnmMValue = true;
    var numberValue = Number(str);
    console.log(numberValue);
    if (numberValue == 0) {
      this.event1.ctnmstatusM = "0";
      this.isCtnmMValue = false;
    } else {
      this.isCtnmMValue = true;
      this.event1.ctnmstatusM = str;
    }
    console.log("this.event1.ctnmstatusM", this.event1.ctnmstatusM);
  }

  isPtnmMValue = false;
  public pTNMStatusModelChange(str: string): void {
    console.log(str);
    this.isPtnmMValue = true;
    var numberValue = Number(str);
    console.log(numberValue);
    if (numberValue == 0) {
      this.event1.ptnmstatusM = "0";
      this.isPtnmMValue = false;
    } else {
      this.isPtnmMValue = true;
      this.event1.ptnmstatusM = str;
    }
  }

  addEvent1Save = false;
  addEvent1() {
    this.addEvent1Save = true;
    console.log("1st event");
    this.event1.eventType = "FIRST_OCCURRENCE_OR_RELAPSE";
    this.event1.patientId = this.pid;
    console.log("evert", this.event1);
    this.eventService.addEvent(this.event1).subscribe(
      (response) => {
        this.addEvent1Save = false;
        console.log("event response", response);
        this.statusMsg = "Successfully Added";
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        console.log(error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
        this.addEvent1Save = false;
      }
    );
  }

  addTreatementChemotherapy() {
    this.treatementChemotherapySpinner = true;
    this.treatementChemotherapy.patientId = this.pid;
    this.treatementChemotherapy.eventType = "TREATMENT";
    this.treatementChemotherapy.treatmentType = "CHEMOTHERAPY";
    console.log("treatementChemotherapy", this.treatementChemotherapy);
    this.eventService.addEvent(this.treatementChemotherapy).subscribe(
      (response) => {
        this.treatementChemotherapySpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementChemotherapySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  drinkChange(drinking: string) {
    console.log(drinking);
  }

  addTreatementSurgery() {
    this.treatementSurgerySpinner = true;
    this.treatementSurgery.patientId = this.pid;
    this.treatementSurgery.eventType = "TREATMENT";
    this.treatementSurgery.treatmentType = "SURGERY";
    console.log("TreatementSurgery", this.treatementSurgery);
    this.eventService.addEvent(this.treatementSurgery).subscribe(
      (response) => {
        this.treatementSurgerySpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementSurgerySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  addTreatementRadiation() {
    this.treatementRadiationSpinner = true;
    this.treatementRadiation.patientId = this.pid;
    this.treatementRadiation.eventType = "TREATMENT";
    this.treatementRadiation.treatmentType = "RADIATION";
    console.log("this.treatementRadiation", this.treatementRadiation);
    this.eventService.addEvent(this.treatementRadiation).subscribe(
      (response) => {
        this.treatementRadiationSpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementRadiationSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  immunoDrugsListSelect = [];

  generateImmunoCycles() {
    console.log(this.immunoDrugsListSelect);
    this.treatementImmunotherapy.immunoCycles = [];
    console.log(
      "start date: " +
        this.firstChemoDate +
        ", interval:" +
        this.cycleInterval +
        ", noc:" +
        this.noc
    );
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as ImmunoCycles;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.immunoDrugDosages = [];
      for (let i = 0; i < this.addImmunoDosages.immunoDosages.length; i++) {
        let dosage = {} as ImmunoDrugDosageList;
        let drugById = this.immunoDrugs.filter(
          (immunoObj) =>
            immunoObj.name === this.addImmunoDosages.immunoDosages[i].drugName
        );
        this.addImmunoDosages.immunoDosages[i].drugId = drugById[0].id;
        dosage.drugId = this.addImmunoDosages.immunoDosages[i].drugId;
        dosage.drugName = this.addImmunoDosages.immunoDosages[i].drugName;
        dosage.dosage = this.addImmunoDosages.immunoDosages[i].dosage;
        dosage.date = new Date(
          new Date(this.firstChemoDate).getTime() +
            index * this.cycleInterval * (1000 * 60 * 60 * 24)
        );
        cycle.immunoDrugDosages.push(dosage);
      }
      this.treatementImmunotherapy.immunoCycles.push(cycle);
      console.log(
        "this.treatementChemotherapy.immunoCycles",
        this.treatementImmunotherapy.immunoCycles
      );
    }
  }

  addImmunoDosage() {
    let drugById = this.immunoDrugs.filter(
      (immunoObj) => immunoObj.name === this.add_immuno_dosage.drugName
    );
    this.add_immuno_dosage.drugId = drugById[0].id;
    this.treatementImmunotherapy.immunoCycles[
      this.edit_immuno_cycle_index
    ].immunoDrugDosages[this.edit_immuno_dosage_index] = this.add_immuno_dosage;
    this.edit_immuno_dosage_toggle = false;
    this.add_immuno_dosage = {} as ImmunoDrugDosageList;
    console.log(this.treatementImmunotherapy.immunoCycles);
  }

  editImmunoDosage(
    edit_immuno_dosage: ImmunoDrugDosageList,
    cycleIndex: number,
    dosageIndex: number
  ) {
    console.log(edit_immuno_dosage);
    this.edit_immuno_dosage_index = dosageIndex;
    this.edit_immuno_cycle_index = cycleIndex;
    this.edit_immuno_dosage_toggle = true;
    // this.editdosage_date = false;
    this.add_immuno_dosage = { ...edit_immuno_dosage };
  }

  deleteImmunoDosage(cycleIndex, dosageIndex) {
    console.log(cycleIndex, dosageIndex);
    this.treatementImmunotherapy.immunoCycles[
      cycleIndex
    ].immunoDrugDosages.splice(dosageIndex, 1);
  }

  addTreatementImmunotherapy() {
    this.treatementImmunotherapySpinner = true;
    this.treatementImmunotherapy.patientId = this.pid;
    this.treatementImmunotherapy.eventType = "TREATMENT";
    this.treatementImmunotherapy.treatmentType = "IMMUNOTHERAPY";
    console.log("this.treatementImmunotherapy", this.treatementImmunotherapy);
    this.eventService.addEvent(this.treatementImmunotherapy).subscribe(
      (response) => {
        this.treatementImmunotherapySpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementImmunotherapySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  addTreatementSupportiveCare() {
    this.treatementSupportiveCareSpinner = true;
    this.treatementSupportiveCare.patientId = this.pid;
    this.treatementSupportiveCare.eventType = "TREATMENT";
    this.treatementSupportiveCare.treatmentType = "SUPPORTIVE_CARE";
    console.log("this.treatementSupportiveCare", this.treatementSupportiveCare);
    this.eventService.addEvent(this.treatementSupportiveCare).subscribe(
      (response) => {
        this.treatementSupportiveCareSpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementSupportiveCareSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  htDrugsListSelect = [];
  htDropdownSettings = {};

  generateHtCycles() {
    this.treatementHormonal.cycles = [];
    console.log(
      "start date: " +
        this.firstChemoDate +
        ", interval:" +
        this.cycleInterval +
        ", noc:" +
        this.noc
    );
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = new Date(
          new Date(this.firstChemoDate).getTime() +
            index * this.cycleInterval * (1000 * 60 * 60 * 24)
        );
        cycle.drugDosages.push(dosage);
      }
      this.treatementHormonal.cycles.push(cycle);
      console.log(
        "this.treatementHormonal.cycles",
        this.treatementHormonal.cycles
      );
    }
  }

  addHtDosage() {
    if (!this.editdosage_toggle) {
      this.treatementHormonal.cycles = [];
      let cycle = {} as AddCycle;
      cycle.cycleDate = this.cycleDate;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        cycle.drugDosages.push(dosage);
      }
      console.log("cycle", cycle);
      this.treatementHormonal.cycles.push(cycle);
      console.log(this.treatementHormonal.cycles);
      this.addDosages.dosages = [];
    } else {
      let drugById = this.htDrugs.filter(
        (drugObj) => drugObj.name === this.add_dosage.drugName
      );
      this.add_dosage.drugId = drugById[0].id;
      this.treatementHormonal.cycles[this.editcycle_index].drugDosages[
        this.editdosage_index
      ] = this.add_dosage;
      this.editdosage_toggle = false;
      this.add_dosage = {} as AddDosage;
      console.log(this.treatementHormonal.cycles);
    }
  }

  settedHtCycle() {
    console.log("this.addDosages.dosages", this.addDosages.dosages);
    if (this.editHtCycleModal) {
      console.log("this.editCycleModal", this.editHtCycleModal);
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(
          (drugObj) => drugObj.name === this.addDosages.dosages[i].drugName
        );
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      this.treatementHormonal.cycles[this.editcycle_index] = cycle;
      console.log(this.treatementHormonal.cycles);
    }
  }

  editHtDosage(
    edit_dosage: AddDosage,
    cycleIndex: number,
    dosageIndex: number
  ) {
    console.log(edit_dosage);
    this.addDrugForm = false;
    this.editdosage_index = dosageIndex;
    this.editcycle_index = cycleIndex;
    this.editdosage_toggle = true;
    this.editdosage_date = false;
    this.add_dosage = { ...edit_dosage };
  }

  editHtCycleModal = false;
  editHtCycle(edit_cycle: AddCycle, cycleIndex: number) {
    this.editHtCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editcycle_index = cycleIndex;
    this.add_cycle = { ...edit_cycle };
    this.addDosages.dosages = this.add_cycle.drugDosages;
    console.log(this.addDosages.dosages);
  }

  deleteHtCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementHormonal.cycles.splice(cycleIndex, 1);
  }

  addTreatementHormonal() {
    this.treatementHormonalSpinner = true;
    this.treatementHormonal.patientId = this.pid;
    this.treatementHormonal.eventType = "TREATMENT";
    this.treatementHormonal.treatmentType = "HARMONAL_THERAPY";
    if (this.hormonalDrugTherapy) {
      this.treatementHormonal.harmonalTherapyType = "DRUG_THERAPY";
    }
    if (this.hormonalSurgery) {
      this.treatementHormonal.harmonalTherapyType = "SURGERY";
    }
    if (this.hormonalRadiation) {
      this.treatementHormonal.harmonalTherapyType = "RADIATION";
    }
    console.log("Treatement Hoemonal", this.treatementHormonal);
    this.eventService.addEvent(this.treatementHormonal).subscribe(
      (response) => {
        this.treatementHormonalSpinner = false;
        console.log("response", response);
        this.statusMsg = response["message"];
        this.showSuccess();
        // this.router.navigate(['/patients']);
        this.reload();
      },
      (error) => {
        this.treatementHormonalSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
      }
    );
  }

  addEvent3Save = false;
  addEvent3() {
    this.addEvent3Save = true;
    console.log("3rd event");
    this.event3.eventType = "DIAGNOSTIC_TEST";
    this.event3.patientId = this.pid;
    console.log("event 3", this.event3);
    this.eventService.addEvent(this.event3).subscribe(
      (response) => {
        console.log("event response", response);
        // this.router.navigate(['/patients']);
        this.reload();
        this.statusMsg = response["message"];
        this.showSuccess();
      },
      (error) => {
        console.log(error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
        this.addEvent3Save = false;
      }
    );
  }

  addEvent4Save = false;
  addEvent4() {
    this.addEvent4Save = true;
    console.log("4th event");
    this.event4.eventType = "CONSULTATION";
    this.event4.patientId = this.pid;
    console.log("event 4", this.event4);
    this.eventService.addEvent(this.event4).subscribe(
      (response) => {
        console.log("event response", response);
        // this.router.navigate(['/patients']);
        this.reload();
        this.statusMsg = response["message"];
        this.showSuccess();
      },
      (error) => {
        console.log(error);
        this.statusMsg = error.error["message"];
        this.showSuccess();
        this.addEvent3Save = false;
      }
    );
  }

  editInfo() {
    console.log("edit info");
  }

  getCancerSites() {
    this.medicalService.getCancerSites().subscribe((response) => {
      this.cancerSites = response["payLoad"];
    });
  }

  cancerLocationChange(cancerTypeId) {
    this.cancerTypeIcdCode = this.cancerTypes.find(
      (e) => e.id === parseInt(cancerTypeId)
    ).icdCode;

    this.getCancerSubTypes(cancerTypeId);
  }

  morphologyChange(cancerCellTypeId) {
    this.morphologyIcdCode = this.cancerSubTypes.find(
      (e) => e.id === parseInt(cancerCellTypeId)
    ).icdCode;
  }

  cancersiteicdCode: string;
  cancerTypeIcdCode: string;
  morphologyIcdCode: string;

  getCancerTypes(id) {
    console.log(this.cancerSites.find((e) => e.id == parseInt(id)));
    this.cancersiteicdCode = this.cancerSites.find(
      (e) => e.id == parseInt(id)
    ).icdCode;

    this.reqId.id = id;

    // this.getMorphologyTypes(id);

    this.medicalService.getCancerTypes(this.reqId).subscribe((response) => {
      this.cancerTypes = response["payLoad"];
      console.log("cancer types", this.cancerTypes);
    });
  }

  getCancerSubTypes(id) {
    console.log("cancer type id", id);
    this.reqId.id = id;
    this.medicalService.getCancerSubTypes(this.reqId).subscribe((response) => {
      this.cancerSubTypes = response["payLoad"];
      console.log("cancer sub types", this.cancerSubTypes);
    });
  }

  getLateralites() {
    this.medicalService.getLateralities().subscribe((response) => {
      console.log("Lateralities response : ", response["payLoad"]);
      this.lateralities = response["payLoad"];
      console.log("Laterlaties : " + this.lateralities);
    });
  }

  getHospitals() {
    this.medicalService.getHospitals().subscribe((response) => {
      this.hospitals = response["payLoad"];
      console.log("hospitals", this.hospitals);
    });
  }

  hospitalLocationsForEvent4: HospitalLocation[];

  getHospitalLocations(hospitalId) {
    this.medicalService
      .getHospitalLocations(hospitalId)
      .subscribe((response) => {
        this.hospitalLocations = response["payLoad"];
        console.log("hospitalLocations", this.hospitalLocations);

        if (this.option4) {
          this.hospitalLocationsForEvent4 = response["payLoad"];
        }
      });
  }

  hospitalLocationDetails = {} as HospitalLocation;
  hospitalPhoneNumber: string;

  getHospitalLocation(hospitalLocationId) {
    this.medicalService
      .getHospitalLocation(hospitalLocationId)
      .subscribe((response) => {
        this.hospitalLocationDetails = response["payLoad"];
        console.log("hospitalLocations", this.hospitalLocationDetails);
        this.medicalInfo.hospitalPhoneNumber =
          this.hospitalLocationDetails.contactNumber;
      });
  }

  // getMorphologyTypes(id) {
  //   console.log("cancer site id", id);

  //   this.reqId.id = id;
  //   this.medicalService
  //     .getCancerMorphologyTypes(this.reqId)
  //     .subscribe((response) => {
  //       this.cancerCellTypes = response["payLoad"];
  //       console.log("morpology ", this.cancerCellTypes);
  //     });
  // }

  getCancerCellTypes() {
    this.medicalService.getCancerCellTypes().subscribe((response) => {
      this.cancerCellTypes = response["payLoad"];
    });
  }

  getDiseases() {
    this.medicalService.getDiseases().subscribe((response) => {
      this.diseases = response["payLoad"];
      console.log("diseases", this.diseases);
    });
  }

  dropDown(event) {
    console.log("selected event", event);
    if (event == "First-Diagnosis-Relapse") {
      this.option1 = true;
      this.option2 = false;
      this.option3 = false;
      this.option4 = false;
      this.diagnosticTests();
      this.bioChemicalMarkers();

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
    if (event == "Treatment") {
      this.option1 = false;
      this.option2 = true;
      this.option3 = false;
      this.option4 = false;
    }
    if (event == "Diagnostic-Tests") {
      this.option1 = false;
      this.option2 = false;
      this.option3 = true;
      this.option4 = false;
      this.getTypeOfTests();

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
    if (event == "Consultation") {
      this.option1 = false;
      this.option2 = false;
      this.option3 = false;
      this.option4 = true;

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
  }

  getOccupationsList() {
    this.patientService.getOccupation().subscribe((response) => {
      this.occupationsList = response["payLoad"];
    });
  }

  getLanguages() {
    this.patientService.getLanguages().subscribe((response) => {
      this.languagesB = response["payLoad"];
      console.log("languages", this.languagesB);
    });
  }

  getReligions() {
    this.patientService.getReligion().subscribe((response) => {
      this.religionsB = response["payLoad"];
      console.log("religions", this.religionsB);
    });
  }

  getTypeOfTests() {
    this.eventService.typeOfTests().subscribe((response) => {
      this.typeOfTets = response["payLoad"];
      console.log("response", this.typeOfTets);
    });
  }

  diagnosticTests() {
    this.eventService.diagnosticTests().subscribe((response) => {
      this.diagnosticTestsArr = response["payLoad"];
      console.log("response", this.diagnosticTestsArr);
    });
  }

  bioChemicalMarkers() {
    this.eventService.bioChemicalMarkers().subscribe((response) => {
      this.bioChemicalMarkerArr = response["payLoad"];
      console.log("bio chemical", this.bioChemicalMarkerArr);
    });
  }

  dropDownHormonal(value) {
    console.log("hormonal option", value);
    if (value == "DrugsTherapy") {
      this.hormonalDrugTherapy = true;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getDrugs().subscribe((response) => {
        this.htDrugs = response["payLoad"];
        console.log("drugs types", this.htDrugs);
      });

      this.htDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        // selectAllText: 'Select All',
        // unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
      };
    }
    if (value == "Surgery") {
      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = true;
      this.hormonalRadiation = false;

      this.eventService.getSurgeries().subscribe((response) => {
        this.surgeries = response["payLoad"];
        console.log("surgeries", this.surgeries);
      });
    }
    if (value == "Radiation") {
      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = true;

      this.eventService.getRadiations().subscribe((response) => {
        this.radiationTypes = response["payLoad"];
        console.log("radiation types", this.radiationTypes);
      });
    }
  }

  dropdownSettings = {};

  allDrugs: Drugs[];

  dropDownTreatment(event) {
    console.log("event", event);
    if (event == "Chemotheropy") {
      this.OptionChemotheropy = true;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getDrugs().subscribe((response) => {
        this.allDrugs = response["payLoad"];
        console.log("drugs types", this.allDrugs);
      });

      this.regimen.regimenList().subscribe((response) => {
        this.regimenList = response["payLoad"];
        console.log("regimen list", this.regimenList);
        // this.getDrugs(this.regimenList[0].name);
      });
    }
    if (event == "Surgery") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = true;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getSurgeries().subscribe((response) => {
        this.surgeries = response["payLoad"];
        console.log("surgeries", this.surgeries);
      });

      this.surgeryDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        // selectAllText: 'Select All',
        // unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
      };
    }

    this.surgeryDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    if (event == "Radiation") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = true;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getRadiations().subscribe((response) => {
        this.radiationTypes = response["payLoad"];
        console.log("radiation types", this.radiationTypes);
      });
    }
    if (event == "Supportive_Care") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = true;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
    if (event == "Immunotherapy") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = true;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getImmunoDrugs().subscribe((response) => {
        this.immunoDrugs = response["payLoad"];
        console.log("immuno drugs types", this.immunoDrugs);
      });

      this.dropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        // selectAllText: 'Select All',
        // unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
      };
    }
    if (event == "Hormonal_Therapy") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = true;
    }
  }

  selectedRegimenDrugs: Drugs[];

  changeIsBloodThinningMedicine(value) {
    if (value == "true") {
      this.medicalInfo.isBloodThinningMedications = true;
    } else {
      this.medicalInfo.isBloodThinningMedications = false;
    }
  }

  changeAllergiesValue(value) {
    if (value == "true") {
      this.medicalInfo.isMedicalAllergies = true;
    } else {
      this.medicalInfo.isMedicalAllergies = false;
    }
  }

  getDrugs(name) {
    console.log("regimen name", name);
    this.addDosages.dosages = [];
    if (name == "null") {
      this.isSetCycleButton = false;
      this.selectedRegimenDrugs = [];
      console.log("this.selectedDrugs", this.selectedDrugs);
      // this.eventService.getDrugs().subscribe(
      //   response => {
      //     this.drugs = response['payLoad'];
      //     console.log("drugs types", this.drugs);
      //   }
      // );
    } else {
      this.isSetCycleButton = true;
      let myItems = this.regimenList.filter(
        (regimen1) => regimen1.name === name
      );
      this.treatementChemotherapy.regimenId = myItems[0].id;
      this.drugs = myItems[0].drugs;
      this.selectedRegimenDrugs = this.drugs;
      for (let i = 0; i < this.drugs.length; i++) {
        let dosage = {} as AddDosage;
        dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.drugs[i].name;
        dosage.dosage = 0;
        this.addDosages.dosages.push(dosage);
      }
      console.log("drugs", this.drugs);
      console.log("this.addDosages.dosages", this.addDosages.dosages);
    }
  }

  eventType3 = {} as EventType3;
  summaryDetail = false;
  summaryDetail2 = false;
  summaryDetail1 = false;
  summaryDetail4 = false;

  event4Response = {};

  summaryDetails(event, index) {
    console.log("event", event);
    this.summaryDetail1 = false;
    this.summaryDetail2 = false;
    this.summaryDetail = false;
    this.summaryDetail4 = false;

    if (event["eventType"] == "DIAGNOSTIC_TEST") {
      this.summaryDetail = true;
      this.summaryDetail2 = false;
      this.summaryDetail1 = false;
      this.summaryDetail4 = false;
      this.eventType3 = event;
      this.eventType3.details = event["details"];
    }

    if (event["eventType"] == "TREATMENT") {
      this.summaryDetail = false;
      this.summaryDetail2 = true;
      this.summaryDetail1 = false;
      this.summaryDetail4 = false;
      this.eventType3 = event;
    }

    if (event["eventType"] == "FIRST_OCCURRENCE_OR_RELAPSE") {
      this.summaryDetail = false;
      this.summaryDetail2 = false;
      this.summaryDetail1 = true;
      this.summaryDetail4 = false;
      this.eventType3 = event;
    }

    if (event["eventType"] == "CONSULTATION") {
      this.summaryDetail = false;
      this.summaryDetail2 = false;
      this.summaryDetail1 = false;
      this.summaryDetail4 = true;
      this.event4Response = event;
    }
  }

  deleteEventDetails(eventId) {
    console.log("event Id", eventId);
    this.eventId = eventId;
  }

  deleteEventMaster() {
    console.log(this.eventId);
    this.eventService.deleteEvent(this.eventId).subscribe((response) => {
      console.log(response);
      this.statusMsg = response["message"];
      this.showSuccess();
      this.reload();
    });
  }

  exportToPDF() {
    this.exportSpinner = true;
    this.patientService.exportToPDF(this.pid).subscribe(
      (response) => {
        console.log("exportToPDF", response);
        this.exportSpinner = false;
        const fileURL = URL.createObjectURL(response);
        const tab = window.open(fileURL);
        tab.location.href = fileURL;
      },
      (error) => {
        console.log("error", error);
        this.exportSpinner = false;
      }
    );
  }

  exportExcelSpinner = false;

  exportToExcel() {
    this.exportExcelSpinner = true;
    this.patientService.exportToExcel(this.pid).subscribe((response) => {
      this.exportExcelSpinner = false;
      const blob = new Blob([response], {
        type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const data = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = data;
      link.download = "patient_details.xlsx";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  reload() {
    console.log("reload");
    this.router
      .navigateByUrl("/super_admin/radiations", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/add-patient", this.pid]);
      });
  }

  copyUUID(patientId) {
    // copy text
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = patientId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.statusMsg = "UUID Copied";
    this.showSuccess();
  }

  onchange() {}

  isChecked: boolean = false;
  dateofbirth: string = null;

  onChange(isChecked: boolean) {
    this.isChecked = isChecked;
    console.log(this.isChecked);

    if (this.isChecked) {
      if (!this.patient.dateOfBirth) {
        this.calculateDOB(this.patient.age);
        console.log("estimated DOB " + this.patient.dateOfBirth);
      }
    } else {
      if (!this.patient.dateOfBirth) {
        //  this.patient.age = this.calculateAge(this.dob);
        console.log("estimated DOB " + this.patient.age);
      }
    }
  }

  calculateAge(birthday: Date) {
    var date = new Date(birthday);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    this.patient.age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  onChangeAge() {
    console.log(this.patient.age);
    this.patient.dateOfBirth = null;
    if (this.patient.age) this.calculateDOB(this.patient.age);
  }

  calculateDOB(ageYears: number) {
    var ageYears = ageYears;
    var ageMonths = 5;
    var ageDays = 18;
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var dob = new Date(year - ageYears, month - ageMonths, day - ageDays);
    this.patient.dateOfBirth = dob;
    this.dateofbirth = dob.toLocaleDateString();
  }

  onchangeDOB() {
    console.log(this.patient.dateOfBirth);
    this.patient.age = null;
    if (this.patient.dateOfBirth) this.calculateAge(this.patient.dateOfBirth);
  }

  onChangeBMI() {
    if (this.patient.weight > 0 && this.patient.height > 0) {
      this.patient.bmi =
        (this.patient.weight / this.patient.height / this.patient.height) *
        10000;
    }
  }

  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray;
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      qty: "",
      price: "",
    });
  }

  addQuantity() {
    this.quantities().push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }
}
