import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private httpClient: HttpClient) { }

  addEvent(body){
    return this.httpClient.post('/patient/add_event',body);
  }

  editEvent(body){
    return this.httpClient.post('/patient/edit_event',body);
  }

  deleteEvent(id){
    return this.httpClient.get('/patient/' + id + '/delete_event');
  }

  typeOfTests(){
    return this.httpClient.get('/event/type_of_tests');
  }

  diagnosticTests(){
    return this.httpClient.get('/event/diagnostics_tests');
  }

  bioChemicalMarkers(){
    return this.httpClient.get('/event/bio_chemical_markers');
  }

  getSurgeries(){
    return this.httpClient.get('/event/surgeries');
  }

  getRadiations(){
    return this.httpClient.get('/event/radiations');
  }

  getImmunoDrugs(){
    return this.httpClient.get('/event/immuno_drugs');
  }

  getDrugs(){
    return this.httpClient.get('/event/drugs');
  }

  getEvents(id){
    return this.httpClient.get('/patient/' + id + '/summary');
  }

  getEventById(eventId){
    return this.httpClient.get('/patient/' + eventId + '/get_event');
  }
  
}
