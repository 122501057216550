import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class CancerGradeService {
  constructor(private httpClient: HttpClient) { }


  getCancerGrades() {
    return this.httpClient.get("/cancerGrade/getAllCancerGrades");
  }

  deleteCancerGrade(body) {
    return this.httpClient.post("/cancerGrade/deleteCancerGrade", body);
  }


  addCancerGrade(body) {
    return this.httpClient.post("/cancerGrade/addCancerGrade", body);
  }

}
