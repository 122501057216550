import { HttpClient } from '@angular/common/http';
import { BoundDirectivePropertyAst } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { EmailRegistrationReqDto } from '../lab/lab-patients-view/lab-patients-view.component';

@Injectable({
  providedIn: 'root'
})
export class LabService {





  constructor(private httpClient: HttpClient) { }


  addLabTestProcess(body){
    return this.httpClient.post('/lab/addLabTestProcess',body);
  }


  updateLabTestProcess(body){
    return this.httpClient.post('/lab/updateLabTestProcess',body);
  }

  deleteLabProcess(id){
    return this.httpClient.get('/lab/' + id +'/deleteLabTestProcess');
  }

  getLabTestProcess(){
    return this.httpClient.get('/lab/labTestProcessList');
  }

  getTestProcessesByLabTestId(testId){
    console.log(testId)
    return this.httpClient.get('/lab/getTestProcessesByLabTestId/' + testId);
  }

  getAllTestProcess(){
     return this.httpClient.get('/lab/labTestProcessList');
  }




  addLabTest(body){
    return this.httpClient.post('/lab/add_lab_test',body);
  }



  getLabTests(){
    return this.httpClient.get('/lab/lab_tests');
  }

  deleteLabTest(body){
    return this.httpClient.post('/lab/delete_lab_test',body);
  }

  addLabTestPackage(body){
    return this.httpClient.post('/lab/add_test_package',body);
  }

  updateLabTestPackage(body){
    return this.httpClient.post('/lab/update_test_package',body);
  }

  deleteLabPackage(id){
    return this.httpClient.get('/lab/' + id +'/delete_package');
  }

  getLabTestPackages(){
    return this.httpClient.get('/lab/test_packages');
  }

  changeLabTestStatus(body){
    return this.httpClient.post('/lab/change_test_status',body);
  }

  getTestStatusLog(id){
    return this.httpClient.get('/patient/lab_test/' + id +'/status_log');
  }

  getLabTestStatus(){
    return this.httpClient.get('/lab/lab_test_status');
  }


  sendRegisterEmail(emailRegistrationReqDto: EmailRegistrationReqDto){
    return this.httpClient.post('/lab/send_email_registration',emailRegistrationReqDto);
  }


}
