import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/_services/patient.service';
import { PaginationReqInterface } from 'src/app/_interfaces/pagination';
import { PatientList, PatientListDto } from 'src/app/_interfaces/patient';
import { User } from 'src/app/_interfaces/register';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {

  user = {} as User;
  paginationReq = {} as PaginationReqInterface;
  patientList: PatientListDto[];
  patientListLength: number;

  viewPid = false;
  viewName = true;
  patientId = -1;

  currentPage: number;
  totalPages: number;
  searchStr: string;

  patientSpinner = true;

  constructor(
    private patientService: PatientService
  ) { }

  ngOnInit() {
    this.paginationReq.pageSize = 10;
    this.paginationReq.reqPageNo = 0;
    this.paginationReq.sortBy = 'lastModified';
    this.paginationReq.sortOrder = 'DESC';
    this.getPatientList();

    this.user = JSON.parse(localStorage.getItem('user'));
    console.log("user", this.user);
  }


  public modelChange(str: string): void {
    console.log(str);
    this.paginationReq.searchKey = str;
    console.log(this.paginationReq);
    this.getPatientList();
    // Add code for searching here
  }

  getPatientList() {
    if (this.currentPage == null) {
      this.currentPage = 0;
      this.paginationReq.reqPageNo = this.currentPage;
    }
    this.patientService.getPatientList(this.paginationReq).subscribe(
      data => {
        console.log(data);
        this.patientSpinner = false;
        this.patientList = data['payLoad']['patientList'];

        console.log("this.patientList", this.patientList);

        this.patientListLength = this.patientList.length;
        this.totalPages = data['payLoad']['totalPages'];
        this.currentPage = data['payLoad']['currentPage'] + 1;

      },
      error => {
        console.log(error);
      }
    );
  }

  viewPatientId(patientId) {
    this.viewName = false
    this.viewPid = true;
    this.patientId = patientId;
  }

  viewPatientName() {

    this.viewName = true;
    this.viewPid = false;
    this.patientId = -1;

  }

  changePage(event) {
    console.log("event", event);
    this.currentPage = event;
    this.paginationReq.reqPageNo = this.currentPage - 1;
    this.getPatientList();
  }

  exportToPDF(patientId) {
    console.log("patient id", patientId);
    this.patientService.exportToPDF(patientId).subscribe(
      response => {
        console.log("exportToPDF", response);
        const fileURL = URL.createObjectURL(response);
        const tab = window.open(fileURL);
        tab.location.href = fileURL;
      },
      error => {
        console.log("error", error);
      }
    );
  }

}
