import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEvent1, AddEvent3, AddEvent4, BioChemicalMarker, DiagnosticTest, Events, EventTest, Marker, Surgery, TestTumor, Tumor, TypeOfTest } from 'src/app/_interfaces/events';
import { CancerCellTypes, CancerSites, CancerSubType, CancerType, Hospital, HospitalLocation } from 'src/app/_interfaces/patient';
import { ReqId } from 'src/app/_interfaces/reqId';
import { AddCycle, AddDosage, AddRadiation, Drugs, ImmunoCycles, ImmunoDrugDosageList, ImmunoDrugs, RadiationTypes, RegimenList, SurgeryAdd, TreatementChemotherapy, TreatementHormonal, TreatementImmunotherapy, TreatementRadiation, TreatementSupportiveCare, TreatementSurgery } from 'src/app/_interfaces/treatement';
import { EventService } from 'src/app/_services/event.service';
import { MedicalService } from 'src/app/_services/medical.service';
import { RegimenService } from 'src/app/_services/regimen.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {

  pid: number;
  eventId: number;
  reqId = {} as ReqId;

  option1 = false;
  option2 = false;
  option3 = false;
  option4 = false;

  OptionChemotheropy = false;
  OptionSurgery = false;
  OptionRadiation = false;
  OptionSupportive_Care = false;
  OptionImmunotherapy = false;
  OptionHormonal_Therapy = false;

  hormonalDrugTherapy = false;
  hormonalSurgery = false;
  hormonalRadiation = false;

  isCtnmMValue = false;
  isPtnmMValue = false;
  addEvent1Save = false;
  addEvent3Save = false;

  isSetCycleButton = false;
  editCycleModal = false;
  editImmunoCycleModal = false;

  editHtCycleModal = false;

  diagnosticTestsArr: DiagnosticTest[];
  bioChemicalMarkerArr: BioChemicalMarker[];
  typeOfTets: TypeOfTest[];
  cancerSites: CancerSites[];
  cancerTypes: CancerType[];
  cancerSubTypes: CancerSubType[];
  cancerCellTypes: CancerCellTypes[];
  hospitals: Hospital[];
  hospitalLocations: HospitalLocation[];
  surgeries: Surgery[];
  radiationTypes: RadiationTypes[];
  regimenList: RegimenList[];
  allDrugs: Drugs[];
  selectedRegimenDrugs: Drugs[];
  selectedDrugs: Drugs[];
  drugs: Drugs[];
  immunoDrugs: ImmunoDrugs[];
  htDrugs: Drugs[];
  hospitalLocationsForEvent4: HospitalLocation[];

  surgeryDropdownSettings = {};
  htDropdownSettings = {};

  event1 = {} as AddEvent1;
  event3 = {} as AddEvent3;
  event4 = {} as AddEvent4;
  event = {} as Events;

  treatementChemotherapy = {} as TreatementChemotherapy;
  treatementChemotherapySpinner = false;

  treatementSurgery = {} as TreatementSurgery;
  treatementSurgerySpinner = false;

  treatementRadiation = {} as TreatementRadiation;
  treatementRadiationSpinner = false;

  treatementSupportiveCare = {} as TreatementSupportiveCare;
  treatementSupportiveCareSpinner = false;

  treatementImmunotherapy = {} as TreatementImmunotherapy;
  treatementImmunotherapySpinner = false;

  treatementHormonal = {} as TreatementHormonal;
  treatementHormonalSpinner = false;

  add_immuno_cycle = {} as ImmunoCycles;

  addSurgeries: {
    surgeriesAdd: SurgeryAdd[];
  };

  addRadiations: {
    radiations: AddRadiation[];
  };

  add_marker = {} as Marker;
  editmarker_toggle: boolean;
  editmarker_index: number;
  addMarkers: {
    markers: Marker[];
  };

  addDosages: {
    dosages: AddDosage[];
  }

  add_dosage = {} as AddDosage;
  editdosage_toggle: boolean;
  editdosage_index: number;
  editcycle_index: number;
  editdosage_date = true;


  addDrugForm = false;
  noc: number;
  cycleInterval: number;
  cycleDate: Date;

  add_cycle = {} as AddCycle;

  selectedSurgeries = [];
  surgeryDate: Date;

  add_surgery = {} as SurgeryAdd;
  editsurgery_toggle: boolean;
  editsurgery_index: number;

  add_radiation = {} as AddRadiation;
  editRadiation_toggle: boolean;
  editRadiation_index: number;

  addImmunoDosages: {
    immunoDosages: ImmunoDrugDosageList[];
  }
  add_immuno_dosage = {} as ImmunoDrugDosageList;
  edit_immuno_dosage_toggle: boolean;
  edit_immuno_dosage_index: number;
  edit_immuno_cycle_index: number;
  edit_immuno_dosage_date = true;
  editImmunoCycle_index: number;

  add_tumor = {} as Tumor;
  editTumor_toggle: boolean;
  editTumor_index: number;
  addTumors: {
    tumors: Tumor[];
  };

  add_diagnostic = {} as EventTest;
  editdiagnostic_toggle: boolean;
  editdiagnostic_index: number;
  addDiagnosticTest: {
    diagnosticTests: EventTest[];
  };
  addTestTumors: {
    testTumors: TestTumor[];
  }


  statusMsg: string;

  addEvent = true;

  constructor(
    private eventService: EventService,
    private medicalService: MedicalService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private regimen: RegimenService,
    private snackBar: MatSnackBar
  ) { }

  public cTNMStatusModelChange(str: string): void {
    console.log(str);
    this.isCtnmMValue = true;
    var numberValue = Number(str);
    console.log(numberValue);
    if (numberValue == 0) {
      this.event1.ctnmstatusM = "0";
      this.isCtnmMValue = false;
    } else {
      this.isCtnmMValue = true;
      this.event1.ctnmstatusM = str;
    }
    console.log("this.event1.ctnmstatusM", this.event1.ctnmstatusM);
  }

  public pTNMStatusModelChange(str: string): void {
    console.log(str);
    this.isPtnmMValue = true;
    var numberValue = Number(str);
    console.log(numberValue);
    if (numberValue == 0) {
      this.event1.ptnmstatusM = "0";
      this.isPtnmMValue = false;
    } else {
      this.isPtnmMValue = true;
      this.event1.ptnmstatusM = str;
    }
  }


  ngOnInit() {

    this.pid = +this.routerAct.snapshot.params['id'];
    this.eventId = +this.routerAct.snapshot.params['eventId'];
    console.log(this.pid,this.eventId);


    if(this.eventId){
      this.addEvent = false;
      this.getEvent();
    }

    this.getCancerSites();
    this.getCancerCellTypes();

    this.addMarkers = {
      markers: []
    };

    this.addTumors = {
      tumors: []
    };

    this.addDosages = {
      dosages: []
    };

    this.addSurgeries = {
      surgeriesAdd: []
    };

    this.addRadiations = {
      radiations: []
    };

    this.addImmunoDosages = {
      immunoDosages: []
    };

    this.addDiagnosticTest = {
      diagnosticTests: []
    };

    this.addTestTumors = {
      testTumors: []
    };
  }

  getEvent(){
    this.eventService.getEventById(this.eventId).subscribe(
      response => {
        this.event = response['payLoad'];
        console.clear();
        console.log("edit event obj",this.event);

        if(this.event.eventType == "FIRST_OCCURRENCE_OR_RELAPSE"){
          this.diagnosticTests();
          this.bioChemicalMarkers();
          this.assignEvent1();
        } else if(this.event.eventType == "TREATMENT"){
          this.option2 = true;

          if(this.event['treatmentType'] == "CHEMOTHERAPY"){
            this.assignTreatmenChemo();
          } else if(this.event['treatmentType'] == "SURGERY"){
            this.getSurgeries();
            this.surgeryDropdownSettings = {
              singleSelection: false,
              idField: 'id',
              textField: 'name',
              itemsShowLimit: 3,
              allowSearchFilter: true
            };
            this.assignTreatmentSurgery();
          } else if(this.event['treatmentType'] == "RADIATION"){

            this.eventService.getRadiations().subscribe(
              response => {
                this.radiationTypes = response['payLoad'];
                // console.log("radiation types", this.radiationTypes);
              }
            );

            this.assignTreatmentRadiation();

          } else if(this.event['treatmentType'] == "SUPPORTIVE_CARE"){
            this.assignTreatmentSupportiveCare();
          } else if(this.event['treatmentType'] == "IMMUNOTHERAPY"){
            this.assignTreatmenImmuno();
          } else if(this.event['treatmentType'] == "HARMONAL_THERAPY"){
            this.OptionHormonal_Therapy = true;

            if(this.event['treatmentHtType'] == 'DRUG_THERAPY'){
              this.hormonalDrugTherapy = true;
              this.assignHT();
            }
             if(this.event['treatmentHtType'] == 'SURGERY'){
               this.hormonalSurgery = true;
               this.assignHT();
             }

             if(this.event['treatmentHtType'] == 'RADIATION'){
              this.hormonalRadiation = true;
              this.assignHT();
            }

          }

        }else if(this.event.eventType == "DIAGNOSTIC_TEST"){
          this.getTypeOfTests();
          this.assignEvent3();
        } else if(this.event.eventType == "CONSULTATION"){
          this.getHospitals();
          this.assignEvent4();
        }
      }
    );
  }

  assignEvent1(){
    this.option1 = true;

    // cancer details
    this.event1.cancerCellTypeId = this.event.details['cancerCellType']['id'];
    this.event1.cancerSiteId = this.event.details['cancerSite']['id'];
    this.cancerTypes = this.event.details['cancerSite']['cancerTypeList'];
    if (this.event.details['cancerType'] != null) {
    this.event1.cancerTypeId = this.event.details['cancerType']['id'];
    }
    if (this.event.details['cancerSubType'] != null) {
        this.event1.cancerSubTypeId = this.event.details['cancerSubType']['id'];
      }
      this.cancerSubTypes = this.event.details['cancerType']['cancerSubTypes'];
    this.event1.cancerGrade = this.event.details['cancerGrade'];
    this.event1.cancerStage = this.event.details['cancerStage'];
    this.event1.ageAtDiagnosis = this.event.details['ageAtDiagnosis'];
    this.event1.symptomsBeforeDiagnosis = this.event.details['symptomsBeforeDiagnosis'];

    this.event1.ctnmstatusM = this.event.details['cTNMStatusM'];
    this.event1.ctnmstatusN = this.event.details['cTNMStatusN'];
    this.event1.ctnmstatusT = this.event.details['cTNMStatusT'];
    if(this.event.details['cTNMMetastasis'] != null){
      this.isCtnmMValue = true;
    this.event1.ctnmmetastasis = this.event.details['cTNMMetastasis'];
    }
    this.event1.ptnmstatusM = this.event.details['pTNMStatusM'];
    this.event1.ptnmstatusN = this.event.details['pTNMStatusN'];
    this.event1.ptnmstatusT = this.event.details['pTNMStatusT'];
    if(this.event.details['pTNMMetastasis'] != null){
      this.isPtnmMValue = true;
    this.event1.ptnmmetastasis = this.event.details['pTNMMetastasis'];
    }
    this.addMarkers.markers = this.event1.markers = this.event.details['markers'];
    this.addTumors.tumors = this.event1.tumors = this.event.details['tumors'];
    this.event1.dateOfFirstOccurrence = this.event.details['dateOfFirstOccurrence'];
    this.event1.diagnosticTest = this.event.details['diagnosticTest'];

    this.event1.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.event1.comments = this.event['comments'];

  }

  getEvent3TestName = false;
  assignEvent3(){
    this.option3 = true;
    this.event3.finalOutcome = this.event.details.finalOutcome;
    this.event3.diseaseStatus = this.event['diseaseStatus'];
    this.event3.dateOfDeath =  this.event.details['dateOfDeath'];

    this.addDiagnosticTest.diagnosticTests = this.event3.tests = this.event.details['tests'];
    this.getEvent3TestName = true;
    // console.log("tests assigned",this.event3.tests);

  }

  assignEvent4(){
    this.option4 = true;

    this.event4.comments = this.event['comments'];
    this.event4.date = this.event.details['date'];
    this.event4.doctorName = this.event.details['doctorName'];

    this.event4.hospitalId = this.event.details['hospital']['id'];
    if(this.event4.hospitalId != null){
    this.getHospitalLocations(this.event4.hospitalId);
    this.event4.hospitalLocationId = this.event.details['hospitalLocation']['id'];
    }
  }

  getEventChemoName = false;
  assignTreatmenChemo(){
    this.OptionChemotheropy = true;
    this.getRegimenList();
    this.getDrugsAll();
    this.treatementChemotherapy.regimenId = this.event['treatmentDetails']['selectedRegimen']['id'];
    this.treatementChemotherapy.startDate = this.event['treatmentDetails']['startDate'];
    this.treatementChemotherapy.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.treatementChemotherapy.comments = this.event['comments'];
    this.noc = this.event['treatmentDetails']['cycles']['length'];
    this.treatementChemotherapy.cycles = this.event['treatmentDetails']['cycles'];
    this.getEventChemoName = true;
  }

  getEventSurgeryName = false;
  assignTreatmentSurgery(){
    this.OptionSurgery = true;
    this.treatementSurgery.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.treatementSurgery.comments = this.event['comments'];
    this.treatementSurgery.surgeries = this.addSurgeries.surgeriesAdd = this.event['treatmentDetails']['surgeries'];

    this.getEventSurgeryName = true;
  }

  getEventRadiationName = false;
  assignTreatmentRadiation(){
    this.OptionRadiation = true;
    this.treatementRadiation.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.treatementRadiation.comments = this.event['comments'];
    this.treatementRadiation.radiations = this.addRadiations.radiations = this.event['treatmentDetails']['radiations'];
    this.getEventRadiationName = true;
    console.log(this.treatementRadiation.radiations);
  }

  assignTreatmentSupportiveCare(){
    this.OptionSupportive_Care = true;
    this.treatementSupportiveCare.admissionDate = this.event['treatmentDetails']['admissionDate'];
    this.treatementSupportiveCare.dischargeDate = this.event['treatmentDetails']['dischargeDate'];
    this.treatementSupportiveCare.reasonForAdmission = this.event['treatmentDetails']['reasonForAdmission'];
    this.treatementSupportiveCare.treatmentGiven = this.event['treatmentDetails']['treatmentGiven'];
    this.treatementSupportiveCare.hospitalNameAndLocation = this.event['hospitalAndLocation']
    this.treatementSupportiveCare.comments = this.event['comments']
  }

  getEventImmunoName = false;
  assignTreatmenImmuno(){
    this.OptionImmunotherapy = true;
    this.getImmunoDrugs();

    this.treatementImmunotherapy.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.treatementImmunotherapy.comments = this.event['comments'];
    this.treatementImmunotherapy.immunoCycles = this.event['treatmentDetails']['cycles'];

    this.getEventImmunoName = true;
  }

  assignHT(){

    this.treatementHormonal.hospitalNameAndLocation = this.event['hospitalAndLocation'];
    this.treatementHormonal.comments = this.event['comments'];

    if(this.hormonalDrugTherapy){
      this.getEventChemoName = true;
      this.eventService.getDrugs().subscribe(
        response => {
          this.htDrugs = response['payLoad'];
          console.log("drugs types", this.htDrugs);
        }
      );

      this.treatementHormonal.cycles = this.event['treatmentHTDetails']['cycles'];
    }

    if(this.hormonalSurgery){
      this.getSurgeries();
      this.getEventSurgeryName = true;
      this.treatementHormonal.surgeries = this.addSurgeries.surgeriesAdd = this.event['treatmentHTDetails']['surgeries'];
    }

    if(this.hormonalRadiation){

      this.eventService.getRadiations().subscribe(
        response => {
          this.radiationTypes = response['payLoad'];
          console.log("radiation types", this.radiationTypes);
        }
      );

      this.getEventRadiationName = true;
      this.treatementHormonal.radiations = this.addRadiations.radiations = this.event['treatmentHTDetails']['radiationList'];
    }
  }

  addEvent1() {
    this.addEvent1Save = true;
    console.clear();
    if(this.pid){
      this.event1.eventType = "FIRST_OCCURRENCE_OR_RELAPSE";
      this.event1.patientId = this.pid;
      console.log("event 1", this.event1);
    this.eventService.addEvent(this.event1).subscribe(
      response => {
        this.addEvent1Save = false;
        console.log("event response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent1Save = false;
      }
    );
  } else if(this.eventId){
      this.event1.eventId = this.eventId;
      console.log("event 1", this.event1);
    this.eventService.editEvent(this.event1).subscribe(
      response => {
        this.addEvent1Save = false;
        console.log("event response", response);
        this.statusMsg = "Successfully Edited";
        this.showSuccess();
        this.reload();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent1Save = false;
      }
    );
  }
  }

  addTreatementChemotherapy() {
    this.treatementChemotherapySpinner = true;

    if(this.pid){
      this.treatementChemotherapy.patientId = this.pid;
      this.treatementChemotherapy.eventType = "TREATMENT";
     this.treatementChemotherapy.treatmentType = "CHEMOTHERAPY";
     console.log("add treatementChemotherapy", this.treatementChemotherapy);
    this.eventService.addEvent(this.treatementChemotherapy).subscribe(
      response => {
        this.treatementChemotherapySpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementChemotherapySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementChemotherapy.eventId = this.eventId;
   console.log("edit treatementChemotherapy", this.treatementChemotherapy);
  this.eventService.editEvent(this.treatementChemotherapy).subscribe(
    response => {
      this.treatementChemotherapySpinner = false;
      console.log("response", response);
      this.statusMsg = response['message'];
      this.showSuccess();
      this.reload();
    },
    error => {
      this.treatementChemotherapySpinner = false;
      console.log("error", error);
      this.statusMsg = error.error['message'];
      this.showSuccess();
    }
  );
}
  }

  addTreatementSurgery() {
    this.treatementSurgerySpinner = true;

    console.log("TreatementSurgery", this.treatementSurgery);
    if(this.pid){
      this.treatementSurgery.eventType = "TREATMENT";
    this.treatementSurgery.treatmentType = "SURGERY";
      this.treatementSurgery.patientId = this.pid;
    this.eventService.addEvent(this.treatementSurgery).subscribe(
      response => {
        this.treatementSurgerySpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementSurgerySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementSurgery.eventId = this.eventId;
  this.eventService.editEvent(this.treatementSurgery).subscribe(
    response => {
      this.treatementSurgerySpinner = false;
      console.log("response", response);
      this.statusMsg = response['message'];
      this.showSuccess();
      this.reload();
    },
    error => {
      this.treatementSurgerySpinner = false;
      console.log("error", error);
      this.statusMsg = error.error['message'];
      this.showSuccess();
    }
  );
}
  }

  addTreatementRadiation() {
    this.treatementRadiationSpinner = true;

    if(this.pid){
      this.treatementRadiation.eventType = "TREATMENT";
    this.treatementRadiation.treatmentType = "RADIATION";
      console.clear();
      console.log("add treatementRadiation", this.treatementRadiation);
      this.treatementRadiation.patientId = this.pid;
    this.eventService.addEvent(this.treatementRadiation).subscribe(
      response => {
        this.treatementRadiationSpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementRadiationSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementRadiation.eventId = this.eventId;
    console.clear();
    console.log("edit treatementRadiation", this.treatementRadiation);
    this.eventService.editEvent(this.treatementRadiation).subscribe(
      response => {
        this.treatementRadiationSpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementRadiationSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
    }
  }

  addTreatementSupportiveCare() {
    this.treatementSupportiveCareSpinner = true;

    console.log("this.treatementSupportiveCare", this.treatementSupportiveCare);
    if(this.pid){
      this.treatementSupportiveCare.eventType = "TREATMENT";
    this.treatementSupportiveCare.treatmentType = "SUPPORTIVE_CARE";
      this.treatementSupportiveCare.patientId = this.pid;
    this.eventService.addEvent(this.treatementSupportiveCare).subscribe(
      response => {
        this.treatementSupportiveCareSpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementSupportiveCareSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementSupportiveCare.eventId = this.eventId;
  this.eventService.editEvent(this.treatementSupportiveCare).subscribe(
    response => {
      this.treatementSupportiveCareSpinner = false;
      console.log("response", response);
      this.statusMsg = response['message'];
      this.showSuccess();
      this.reload();
    },
    error => {
      this.treatementSupportiveCareSpinner = false;
      console.log("error", error);
      this.statusMsg = error.error['message'];
      this.showSuccess();
    }
  );
}
  }

  addTreatementImmunotherapy() {
    this.treatementImmunotherapySpinner = true;


    if(this.pid){
      this.treatementImmunotherapy.patientId = this.pid;
      this.treatementImmunotherapy.eventType = "TREATMENT";
    this.treatementImmunotherapy.treatmentType = "IMMUNOTHERAPY";
    console.log("add treatementImmunotherapy", this.treatementImmunotherapy);
    this.eventService.addEvent(this.treatementImmunotherapy).subscribe(
      response => {
        this.treatementImmunotherapySpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementImmunotherapySpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementImmunotherapy.eventId = this.eventId;
    console.log("edit treatementImmunotherapy", this.treatementImmunotherapy);
    // this.eventService.editEvent(this.treatementImmunotherapy).subscribe(
    //   response => {
    //     this.treatementImmunotherapySpinner = false;
    //     console.log("response", response);
    //     this.statusMsg = response['message'];
    //     this.showSuccess();
    //     this.reload();
    //   },
    //   error => {
    //     this.treatementImmunotherapySpinner = false;
    //     console.log("error", error);
    //     this.statusMsg = error.error['message'];
    //     this.showSuccess();
    //   }
    // );
  }
  }

  addTreatementHormonal() {
    this.treatementHormonalSpinner = true;
    if (this.pid){
    this.treatementHormonal.patientId = this.pid;
    this.treatementHormonal.eventType = "TREATMENT";
    this.treatementHormonal.treatmentType = "HARMONAL_THERAPY";
    if (this.hormonalDrugTherapy) {
      this.treatementHormonal.harmonalTherapyType = "DRUG_THERAPY";
    }
    if (this.hormonalSurgery) {
      this.treatementHormonal.harmonalTherapyType = "SURGERY";
    }
    if (this.hormonalRadiation) {
      this.treatementHormonal.harmonalTherapyType = "RADIATION";
    }
    console.log("add Treatement Hormonal", this.treatementHormonal);
    this.eventService.addEvent(this.treatementHormonal).subscribe(
      response => {
        this.treatementHormonalSpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementHormonalSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  } else if(this.eventId){
    this.treatementHormonal.eventId = this.eventId;
    console.log("add Treatement Hormonal", this.treatementHormonal);
    this.eventService.editEvent(this.treatementHormonal).subscribe(
      response => {
        this.treatementHormonalSpinner = false;
        console.log("response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        this.treatementHormonalSpinner = false;
        console.log("error", error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
      }
    );
  }
  }


  addEvent3() {
    this.addEvent3Save = true;

    console.clear();
    if(this.pid){
      this.event3.patientId = this.pid;
      this.event3.eventType = "DIAGNOSTIC_TEST";
      console.log("add event 3", this.event3);
    this.eventService.addEvent(this.event3).subscribe(
      response => {
        console.log("add event response", response);
        this.reload();
        this.statusMsg = response['message'];
        this.showSuccess();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent3Save = false;
      }
    );
    }else if(this.eventId){
      this.event3.eventId = this.eventId;
      console.log("edit event 3", this.event3);
    this.eventService.editEvent(this.event3).subscribe(
      response => {
        this.addEvent3Save = false;
        console.log("event response", response);
        this.statusMsg = "Successfully Edited";
        this.showSuccess();
        this.reload();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent1Save = false;
      }
    );
    }
  }

  addEvent4Save = false;
  addEvent4() {
    this.addEvent4Save = true;
    this.event4.eventType = "CONSULTATION";
    if(this.pid){
    this.event4.patientId = this.pid;
    console.log("add event 4", this.event4);
    this.eventService.addEvent(this.event4).subscribe(
      response => {
        console.log("event response", response);
        this.reload();
        this.statusMsg = response['message'];
        this.showSuccess();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent3Save = false;
      }
    );
  } else if (this.eventId){
    this.event4.eventId = this.eventId;
      console.log("edit event 4", this.event4);
    this.eventService.editEvent(this.event4).subscribe(
      response => {
        this.addEvent1Save = false;
        console.log("event response", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error => {
        console.log(error);
        this.statusMsg = error.error['message'];
        this.showSuccess();
        this.addEvent1Save = false;
      }
    );
  }
  }

  eventTypeDropDown(event) {






    console.log("selected event", event);

    if (event == "First-Diagnosis-Relapse") {
      this.option1 = true;
      this.option2 = false;
      this.option3 = false;
      this.option4 = false;
      this.diagnosticTests();
      this.bioChemicalMarkers();

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
    if (event == "Treatment") {
      this.option1 = false;
      this.option2 = true;
      this.option3 = false;
      this.option4 = false;
    }
    if (event == "Diagnostic-Tests") {
      // let route  = "diagnostic";
      // this.router.navigate([route]);


      // return false;
      this.option1 = false;
      this.option2 = false;
      this.option3 = true;
      this.option4 = false;
      this.getTypeOfTests();

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
    if (event == "Consultation") {
      this.option1 = false;
      this.option2 = false;
      this.option3 = false;
      this.option4 = true;

      this.getHospitals();

      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }
  }

  dropDownTreatment(event) {
    console.log("treatment type:", event);

    if (event == "Chemotheropy") {
      this.OptionChemotheropy = true;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;

      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.getRegimenList();
      this.getDrugsAll();
    }

    if (event == "Surgery") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = true;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;


      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.getSurgeries();


      this.surgeryDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        // selectAllText: 'Select All',
        // unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    }

    if (event == "Radiation") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = true;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;


      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getRadiations().subscribe(
        response => {
          this.radiationTypes = response['payLoad'];
          console.log("radiation types", this.radiationTypes);
        }
      );
    }

    if (event == "Supportive_Care") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = true;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = false;


      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;
    }

    if (event == "Immunotherapy") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = true;
      this.OptionHormonal_Therapy = false;


      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.getImmunoDrugs();
    }

    if (event == "Hormonal_Therapy") {
      this.OptionChemotheropy = false;
      this.OptionSurgery = false;
      this.OptionRadiation = false;
      this.OptionSupportive_Care = false;
      this.OptionImmunotherapy = false;
      this.OptionHormonal_Therapy = true;
    }
  }

  dropDownHormonal(value) {
    console.log("hormonal option", value);
    if (value == "DrugsTherapy") {
      this.hormonalDrugTherapy = true;
      this.hormonalSurgery = false;
      this.hormonalRadiation = false;

      this.eventService.getDrugs().subscribe(
        response => {
          this.htDrugs = response['payLoad'];
          console.log("drugs types", this.htDrugs);
        }
      );

      this.htDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
        allowSearchFilter: true
      }


    }
    if (value == "Surgery") {
      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = true;
      this.hormonalRadiation = false;

      this.getSurgeries();
    }
    if (value == "Radiation") {
      this.hormonalDrugTherapy = false;
      this.hormonalSurgery = false;
      this.hormonalRadiation = true;

      this.eventService.getRadiations().subscribe(
        response => {
          this.radiationTypes = response['payLoad'];
          console.log("radiation types", this.radiationTypes);
        }
      );
    }
  }

  addMarker() {
    if (!this.editmarker_toggle) {
      this.addMarkers.markers.push(this.add_marker);
      console.log(this.add_marker);
      this.add_marker = {} as Marker;
    }
    else {
      this.addMarkers.markers[this.editmarker_index] = this.add_marker;
      this.editmarker_toggle = false;
      this.add_marker = {} as Marker;
    }
    this.event1.markers = this.addMarkers.markers;
  }

  showeditMarker(edit_marker: Marker, index: number) {
    console.log(index);
    this.editmarker_index = index;
    this.editmarker_toggle = true;
    this.add_marker = { ...edit_marker };
  }

  deleteMarker(index) {
    console.log(index);
    this.addMarkers.markers.splice(index, 1);
    this.event1.markers = this.addMarkers.markers;

  }

  addTumor() {
    if (!this.editTumor_toggle) {
      this.addTumors.tumors.push(this.add_tumor);
      console.log(this.add_tumor);
      this.add_tumor = {} as Tumor;
    } else {
      this.addTumors.tumors[this.editTumor_index] = this.add_tumor;
      this.editTumor_toggle = false;
      this.add_tumor = {} as Tumor;
    }
    this.event1.tumors = this.addTumors.tumors;
  }

  editTumor(edit_tumor: Tumor, index: number) {
    console.log(index);
    this.editTumor_index = index;
    this.editTumor_toggle = true;
    this.add_tumor = { ...edit_tumor };
  }

  removeTumor(i) {
    console.log("remove tumor", i);
    this.addTumors.tumors.splice(i, 1)
    this.event1.tumors = this.addTumors.tumors;
  }

  addEventTest() {
    if (!this.editdiagnostic_toggle) {
      this.add_diagnostic.testTumors = this.addTestTumors.testTumors;
      this.addTestTumors.testTumors = [];
      this.addDiagnosticTest.diagnosticTests.push(this.add_diagnostic);
      console.log(this.add_diagnostic);
      this.add_diagnostic = {} as EventTest;
    }
    else {
      this.getEvent3TestName = false;
      this.add_diagnostic.testTumors = this.addTestTumors.testTumors;
      this.addTestTumors.testTumors = [];
      this.addDiagnosticTest.diagnosticTests[this.editdiagnostic_index] = this.add_diagnostic;
      console.log("edit ",this.add_diagnostic);
      this.editdiagnostic_toggle = false;
      this.add_diagnostic = {} as EventTest;
    }
    this.event3.tests = this.addDiagnosticTest.diagnosticTests;
    console.log(this.event3.tests);
  }

  deleteEventTest(index) {
    console.log(index);
    this.addDiagnosticTest.diagnosticTests.splice(index, 1);
    this.event3.tests = this.addDiagnosticTest.diagnosticTests;

  }

  ediEventTest(edit_test: EventTest, index: number) {
    console.log(index);
    this.editdiagnostic_index = index;
    this.editdiagnostic_toggle = true;
    this.add_diagnostic = { ...edit_test };
    // this.add_diagnostic.testTumorDtoList = { ...edit_test.testTumorDtoList };
    if(this.eventId){
      this.addTestTumors.testTumors = edit_test['testTumors'];
      if(this.getEvent3TestName){
        this.add_diagnostic.typeOfTest = edit_test['typeOfTest']['name'];
      }
    } else if(this.pid){
    this.addTestTumors.testTumors = this.add_diagnostic.testTumors;
    }
    console.log("edit button clicked",this.add_diagnostic);
  }

  addTestTumor(): void {
    this.addTestTumors.testTumors.push({
      tumorLength: '',
      tumorWidth: '',
      tumorVolume:''
    })
    console.log(this.addTestTumors.testTumors);
  }

  removeTestTumor(i) {
    console.log("remove tumor", i);
    this.addTestTumors.testTumors.splice(i, 1)
  }

  getCancerSites() {
    this.medicalService.getCancerSites().subscribe(
      response => {
        this.cancerSites = response['payLoad'];
      }
    );
  }

  getCancerTypes(id) {
    console.log("cancer site id", id);
    this.reqId.id = id;
    this.medicalService.getCancerTypes(this.reqId).subscribe(
      response => {
        this.cancerTypes = response['payLoad'];
        console.log("cancer types", this.cancerTypes);

      }
    );
  }

  getCancerSubTypes(id) {
    console.log("cancer type id", id);
    this.reqId.id = id;
    this.medicalService.getCancerSubTypes(this.reqId).subscribe(
      response => {
        this.cancerSubTypes = response['payLoad'];
        console.log("cancer sub types", this.cancerSubTypes);

      }
    );
  }

  getCancerCellTypes() {
    this.medicalService.getCancerCellTypes().subscribe(
      response => {
        this.cancerCellTypes = response['payLoad'];
      }
    );
  }

  diagnosticTests() {
    this.eventService.diagnosticTests().subscribe(
      response => {
        this.diagnosticTestsArr = response['payLoad'];
        console.log("diagnosticTests", this.diagnosticTestsArr);
      }
    );
  }

  bioChemicalMarkers() {
    this.eventService.bioChemicalMarkers().subscribe(
      response => {
        this.bioChemicalMarkerArr = response['payLoad'];
        console.log("biochemical markers", this.bioChemicalMarkerArr);
      }
    );
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  getDrugs(name) {
    console.log("regimen name", name);
    this.addDosages.dosages = [];
    if (name == 'null') {
      this.isSetCycleButton = false;
      this.selectedRegimenDrugs = [];
      console.log("this.selectedDrugs", this.selectedDrugs);
    } else {
      this.isSetCycleButton = true;
      let selectedRegimen = this.regimenList.filter(regimen1 => regimen1.name === name);
      this.treatementChemotherapy.regimenId = selectedRegimen[0].id;
      this.drugs = selectedRegimen[0].drugs;
      this.selectedRegimenDrugs = this.drugs;
      for (let i = 0; i < this.drugs.length; i++) {
        let dosage = {} as AddDosage;
        dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.drugs[i].name;
        dosage.dosage = 0;
        this.addDosages.dosages.push(dosage);
      }
      console.log("drugs", this.drugs);
      console.log("this.addDosages.dosages", this.addDosages.dosages)
    }
  }

  addDosageManually(): void {
    this.addDosages.dosages.push({
      dosage: 0,
      drugId: 0,
      drugName: '',
      date: new Date(),
    })
    console.log(this.addDosages.dosages);
  }

  settedCycle() {
    this.getEventChemoName = false;
    console.log("this.addDosages.dosages", this.addDosages.dosages);
    if (this.editCycleModal) {
      console.log("this.editCycleModal", this.editCycleModal);
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.allDrugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      this.treatementChemotherapy.cycles[this.editcycle_index] = cycle;
      console.log(this.treatementChemotherapy.cycles);
    }
    // this.addDosages.dosages = [];
  }

  viewCycle = false;
  generateCycles() {
    this.viewCycle = true;
    // console.log("this drugs",this.drugs);
    this.treatementChemotherapy.cycles = [];
    console.log("start date: " + this.treatementChemotherapy.startDate + ", interval:" + this.cycleInterval + ", noc:" + this.noc);
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.allDrugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.addDosages.dosages[i].drugId;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = new Date(new Date(this.treatementChemotherapy.startDate).getTime() + ((index) * this.cycleInterval * (1000 * 60 * 60 * 24)));
        console.log(dosage);
        cycle.drugDosages.push(dosage);
      }
      this.treatementChemotherapy.cycles.push(cycle);
      console.log("this.treatementChemotherapy.cycles", this.treatementChemotherapy.cycles);
    }
    // this.addDosages.dosages = [];
  }

  addDosage() {
    if (!this.editdosage_toggle) {
      // this.treatementChemotherapy.cycles = [];
      let cycle = {} as AddCycle;
      // cycle.cycleDate = this.cycleDate;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.drugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      console.log("cycle", cycle);
      this.treatementChemotherapy.cycles.push(cycle);
      console.log(this.treatementChemotherapy.cycles);
      this.addDosages.dosages = [];
    } else {
      let drugById = this.drugs.filter(drugObj => drugObj.name === this.add_dosage.drugName);
      this.add_dosage.drugId = drugById[0].id;
      this.treatementChemotherapy.cycles[this.editcycle_index].drugDosages[this.editdosage_index] = this.add_dosage;
      this.editdosage_toggle = false;
      this.add_dosage = {} as AddDosage;
      console.log(this.treatementChemotherapy.cycles);
    }
  }

  editDosage(edit_dosage: AddDosage, cycleIndex: number, dosageIndex: number) {
    this.addDrugForm = false;
    this.editdosage_index = dosageIndex;
    this.editcycle_index = cycleIndex;
    this.editdosage_toggle = true;
    this.editdosage_date = false;
    this.add_dosage = { ...edit_dosage };
  }

  deleteDosage(cycleIndex, dosageIndex) {
    console.log(cycleIndex, dosageIndex);
    this.treatementChemotherapy.cycles[cycleIndex].drugDosages.splice(dosageIndex, 1);
  }

  removeDosage(index){
    console.log("index",index);
    this.addDosages.dosages.splice(index, 1);
   }

  changeAddDosageDate($event, i) {
    console.log($event.target.value, i)
    this.addDosages.dosages[i].date = new Date($event.target.value);
  }

  editCycle(edit_cycle: AddCycle, cycleIndex: number) {
    this.editCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editcycle_index = cycleIndex;
    this.add_cycle = { ...edit_cycle };
    this.addDosages.dosages = this.add_cycle.drugDosages;
    console.log(this.addDosages.dosages);
  }

  deleteCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementChemotherapy.cycles.splice(cycleIndex, 1);
  }

  addSurgery() {
    console.log("this.selectedSurgeries", this.selectedSurgeries);
    if (!this.editsurgery_toggle) {
      console.log("add_surgery", this.add_surgery);
      if(this.selectedSurgeries.length > 0){
      for (let i = 0; i < this.selectedSurgeries.length; i++) {
        this.add_surgery.surgeryId = this.selectedSurgeries[i].id;
        this.add_surgery.surgeryName = this.selectedSurgeries[i].name;
        this.add_surgery.date = this.surgeryDate;
        this.addSurgeries.surgeriesAdd.push(this.add_surgery);
        this.add_surgery = {} as SurgeryAdd;
      }
    }
      if(this.hormonalSurgery){
        let surgeryById = this.surgeries.filter(surgeryObj => surgeryObj.name === this.add_surgery.surgeryName);
        this.add_surgery.surgeryId = surgeryById[0].id;
        this.addSurgeries.surgeriesAdd.push(this.add_surgery);
      }

      this.add_surgery = {} as SurgeryAdd;
      this.selectedSurgeries = [];
    } else {
      console.log("edit surgery", this.add_surgery);
      this.getEventSurgeryName = false;
      let surgeryById = this.surgeries.filter(surgeryObj => surgeryObj.name === this.add_surgery.surgeryName);
      this.add_surgery.surgeryId = surgeryById[0].id;
      this.addSurgeries.surgeriesAdd[this.editsurgery_index] = this.add_surgery;
      this.add_surgery.date = this.surgeryDate;
      this.editsurgery_toggle = false;
      this.add_surgery = {} as SurgeryAdd;
    }
    if (this.hormonalSurgery) {
      this.treatementHormonal.surgeries = this.addSurgeries.surgeriesAdd;
    } else {
      this.treatementSurgery.surgeries = this.addSurgeries.surgeriesAdd;
    }
  }

  editSurgery(edit_surgery: SurgeryAdd, index: number) {
    console.log(index);
    this.editsurgery_index = index;
    this.editsurgery_toggle = true;
    this.add_surgery = { ...edit_surgery };
    if(this.eventId){
      this.surgeryDate = edit_surgery.date;
      if(this.getEventSurgeryName){
        this.add_surgery.surgeryName = edit_surgery['surgery']['name'];
      }
    }
    console.log(this.add_surgery);
  }

  deleteSurgery(index) {
    console.log(index);
    this.addSurgeries.surgeriesAdd.splice(index, 1);
    this.treatementSurgery.surgeries = this.addSurgeries.surgeriesAdd;

  }

  closeSurgeryModal(){
    this.add_surgery = {} as SurgeryAdd;
  }

  addRadiation() {
    if (!this.editRadiation_toggle) {
      console.log("add_radiation", this.add_radiation);
      let radiationById = this.radiationTypes.filter(radiationObj => radiationObj.name === this.add_radiation.radiationTypeName);
      this.add_radiation.radiationTypeId = radiationById[0].id;
      this.addRadiations.radiations.push(this.add_radiation);
      this.add_radiation = {} as AddRadiation;
    } else {
      this.getEventRadiationName = false;
      console.log("edit surgery", this.add_radiation);
      let radiationById = this.radiationTypes.filter(radiationObj => radiationObj.name === this.add_radiation.radiationTypeName);
      this.add_radiation.radiationTypeId = radiationById[0].id;
      this.addRadiations.radiations[this.editRadiation_index] = this.add_radiation;
      this.editRadiation_toggle = false;
      this.add_radiation = {} as AddRadiation;
    }
    if (this.hormonalRadiation) {
      this.treatementHormonal.radiations = this.addRadiations.radiations;
    } else {
      this.treatementRadiation.radiations = this.addRadiations.radiations;
    }
  }

  editRadiation(edit_radiation: AddRadiation, index: number) {

    this.editRadiation_index = index;
    this.editRadiation_toggle = true;
    this.add_radiation = { ...edit_radiation };
    if(this.getEventRadiationName){
      this.add_radiation.radiationTypeName = edit_radiation['radiationType']['name'];
    }
    console.log(edit_radiation);
  }

  deleteRadiation(index) {
    console.log(index);
    this.addRadiations.radiations.splice(index, 1);
    this.treatementRadiation.radiations = this.addRadiations.radiations;

  }

  addImmunoDosageManually(): void {
    this.addImmunoDosages.immunoDosages.push({
      dosage: 0,
      drugId: 0,
      drugName: '',
      date: new Date(),
      otherSpecify: ''
    })
    console.log(this.addImmunoDosages.immunoDosages);
  }

  removeImmunoDosage(i){
    this.addImmunoDosages.immunoDosages.splice(i,1);
  }

  changeAddDosageDateImmuno($event, i) {
    console.log($event.target.value, i)
    this.addImmunoDosages.immunoDosages[i].date = new Date($event.target.value);
  }

  immunoDrugsListSelect = [];
  firstChemoDate: Date;
  generateImmunoCycles() {
    console.log(this.immunoDrugsListSelect);
    this.treatementImmunotherapy.immunoCycles = [];
    console.log("start date: " + this.firstChemoDate + ", interval:" + this.cycleInterval + ", noc:" + this.noc);
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as ImmunoCycles;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.immunoDrugDosages = [];
      for (let i = 0; i < this.addImmunoDosages.immunoDosages.length; i++) {
        let dosage = {} as ImmunoDrugDosageList;
        let drugById = this.immunoDrugs.filter(immunoObj => immunoObj.name === this.addImmunoDosages.immunoDosages[i].drugName);
        this.addImmunoDosages.immunoDosages[i].drugId = drugById[0].id;
        dosage.drugId = this.addImmunoDosages.immunoDosages[i].drugId;
        dosage.drugName = this.addImmunoDosages.immunoDosages[i].drugName;
        dosage.dosage = this.addImmunoDosages.immunoDosages[i].dosage;
        dosage.date = new Date(new Date(this.firstChemoDate).getTime() + ((index) * this.cycleInterval * (1000 * 60 * 60 * 24)));
        cycle.immunoDrugDosages.push(dosage);
      }
      this.treatementImmunotherapy.immunoCycles.push(cycle);
      console.log("this.treatementChemotherapy.immunoCycles", this.treatementImmunotherapy.immunoCycles);
    }
  }

  settedImmunoCycle() {
    this.getEventImmunoName = false;
    console.log("this.addImmunoDosages.immunoDosages", this.addImmunoDosages.immunoDosages);
    if (this.editImmunoCycleModal) {
      console.log("this.editCycleModal", this.editImmunoCycleModal);
      let cycle = {} as ImmunoCycles;
      // cycle.cycleDate = new Date(new Date(this.firstChemoDate).getTime() + ((index + 1) * this.cycleInterval * (1000 * 60 * 60 * 24)));
      cycle.immunoDrugDosages = [];
      for (let i = 0; i < this.addImmunoDosages.immunoDosages.length; i++) {
        let dosage = {} as ImmunoDrugDosageList;
        let drugById = this.immunoDrugs.filter(drugObj => drugObj.name === this.addImmunoDosages.immunoDosages[i].drugName);
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addImmunoDosages.immunoDosages[i].drugName;
        dosage.dosage = this.addImmunoDosages.immunoDosages[i].dosage;
        dosage.date = this.addImmunoDosages.immunoDosages[i].date;
        cycle.immunoDrugDosages.push(dosage);
      }
      this.treatementImmunotherapy.immunoCycles[this.editImmunoCycle_index] = cycle;
      console.log(this.treatementImmunotherapy.immunoCycles);
    }

  }

  editImmunoCycle(edit_cycle: ImmunoCycles, cycleIndex: number) {
    this.editImmunoCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editImmunoCycle_index = cycleIndex;
    this.add_immuno_cycle = { ...edit_cycle };
    this.addImmunoDosages.immunoDosages = this.add_immuno_cycle.immunoDrugDosages;
    console.log(this.addImmunoDosages.immunoDosages);
  }

  deleteImmuCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementImmunotherapy.immunoCycles.splice(cycleIndex, 1);
  }

  addImmunoDosage() {
    let drugById = this.immunoDrugs.filter(immunoObj => immunoObj.name === this.add_immuno_dosage.drugName);
    this.add_immuno_dosage.drugId = drugById[0].id;
    this.treatementImmunotherapy.immunoCycles[this.edit_immuno_cycle_index].immunoDrugDosages[this.edit_immuno_dosage_index] = this.add_immuno_dosage;
    this.edit_immuno_dosage_toggle = false;
    this.add_immuno_dosage = {} as ImmunoDrugDosageList;
    console.log(this.treatementImmunotherapy.immunoCycles);
  }

  editImmunoDosage(edit_immuno_dosage: ImmunoDrugDosageList, cycleIndex: number, dosageIndex: number) {
    console.log(edit_immuno_dosage);
    this.edit_immuno_dosage_index = dosageIndex;
    this.edit_immuno_cycle_index = cycleIndex;
    this.edit_immuno_dosage_toggle = true;
    // this.editdosage_date = false;
    this.add_immuno_dosage = { ...edit_immuno_dosage };
  }

  deleteImmunoDosage(cycleIndex, dosageIndex) {
    console.log(cycleIndex, dosageIndex);
    this.treatementImmunotherapy.immunoCycles[cycleIndex].immunoDrugDosages.splice(dosageIndex, 1);
  }

  settedHtCycle() {
    console.log("this.addDosages.dosages", this.addDosages.dosages);
    if (this.editHtCycleModal) {
      console.log("this.editCycleModal", this.editHtCycleModal);
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = this.addDosages.dosages[i].date;
        cycle.drugDosages.push(dosage);
      }
      this.treatementHormonal.cycles[this.editcycle_index] = cycle;
      console.log(this.treatementHormonal.cycles);
    }
  }

  generateHtCycles() {
    this.treatementHormonal.cycles = [];
    console.log("start date: " + this.firstChemoDate + ", interval:" + this.cycleInterval + ", noc:" + this.noc);
    for (let index = 0; index < this.noc; index++) {
      let cycle = {} as AddCycle;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        dosage.date = new Date(new Date(this.firstChemoDate).getTime() + ((index) * this.cycleInterval * (1000 * 60 * 60 * 24)));
        cycle.drugDosages.push(dosage);
      }
      this.treatementHormonal.cycles.push(cycle);
      console.log("this.treatementHormonal.cycles", this.treatementHormonal.cycles);
    }
  }

  addHtDosage() {
    if (!this.editdosage_toggle) {
      this.treatementHormonal.cycles = [];
      let cycle = {} as AddCycle;
      cycle.cycleDate = this.cycleDate;
      cycle.drugDosages = [];
      for (let i = 0; i < this.addDosages.dosages.length; i++) {
        let dosage = {} as AddDosage;
        let drugById = this.htDrugs.filter(drugObj => drugObj.name === this.addDosages.dosages[i].drugName);
        dosage.drugId = drugById[0].id;
        // dosage.drugId = this.drugs[i].id;
        dosage.drugName = this.addDosages.dosages[i].drugName;
        dosage.dosage = this.addDosages.dosages[i].dosage;
        cycle.drugDosages.push(dosage);
      }
      console.log("cycle", cycle);
      this.treatementHormonal.cycles.push(cycle);
      console.log(this.treatementHormonal.cycles);
      this.addDosages.dosages = [];
    } else {
      let drugById = this.htDrugs.filter(drugObj => drugObj.name === this.add_dosage.drugName);
      this.add_dosage.drugId = drugById[0].id;
      this.treatementHormonal.cycles[this.editcycle_index].drugDosages[this.editdosage_index] = this.add_dosage;
      this.editdosage_toggle = false;
      this.add_dosage = {} as AddDosage;
      console.log(this.treatementHormonal.cycles);
    }
  }

  editHtCycle(edit_cycle: AddCycle, cycleIndex: number) {
    this.editHtCycleModal = true;
    console.log(edit_cycle, cycleIndex);
    this.editcycle_index = cycleIndex;
    this.add_cycle = { ...edit_cycle };
    this.addDosages.dosages = this.add_cycle.drugDosages;
    console.log(this.addDosages.dosages);
  }

  deleteHtCycle(cycleIndex) {
    console.log(cycleIndex);
    this.treatementHormonal.cycles.splice(cycleIndex, 1);
  }

  changeAddDosageDateHt($event, i) {
    console.log($event.target.value, i)
    this.addDosages.dosages[i].date = new Date($event.target.value);
  }

  getTypeOfTests() {
    this.eventService.typeOfTests().subscribe(
      response => {
        this.typeOfTets = response['payLoad'];
        console.log("typeOfTests", this.typeOfTets);
      }
    );
  }

  getHospitals(){
    this.medicalService.getHospitals().subscribe(
      response => {
        this.hospitals = response['payLoad'];
        console.log("hospitals",this.hospitals);
      });
  }

  getHospitalLocations(hospitalId){
    this.medicalService.getHospitalLocations(hospitalId).subscribe(
      response => {
        this.hospitalLocations = response['payLoad'];
        console.log("hospitalLocations",this.hospitalLocations);

        if(this.option4){
          this.hospitalLocationsForEvent4 = response['payLoad'];
        }
      });
  }

  getRegimenList(){
    this.regimen.regimenList().subscribe(
      response => {
        this.regimenList = response['payLoad'];
        // console.log("regimen list", this.regimenList);
      }
    );
  }

  getDrugsAll(){
  this.eventService.getDrugs().subscribe(
    response => {
      this.allDrugs = response['payLoad'];
      // console.log("drugs types", this.allDrugs);
    }
  );
  }

  getSurgeries(){
    this.eventService.getSurgeries().subscribe(
      response => {
        this.surgeries = response['payLoad'];
        console.log("surgeries", this.surgeries);
      }
    );
  }

  getImmunoDrugs(){
  this.eventService.getImmunoDrugs().subscribe(
    response => {
      this.immunoDrugs = response['payLoad'];
      // console.log("immuno drugs types", this.immunoDrugs);
    }
  );
}

  showSuccess() {
    this.snackBar.open(this.statusMsg, 'dismiss', {
      duration: 2000,
      verticalPosition: 'top'
    });
  }

  reload() {
    console.log("reload");
    if(this.pid){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/add-patient', this.pid]);
      });
    } else if(this.eventId){
    this.router.navigateByUrl('/super_admin/radiations', { skipLocationChange: true }).then(() => {
      this.router.navigate(['edit_event', this.eventId]);
    });
  }
  }

}
