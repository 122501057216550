import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_guard/auth.guard';
import { PagesComponent } from './pages.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { PatientsComponent } from './patients/patients.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { AddEventComponent } from './add-event/add-event.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,

    children: [
      {
        path: '',
        component: PatientsComponent
      },
      {
        path: 'patients',
        component: PatientsComponent
      },
      {
        path: 'add-patient',
        component: PatientInfoComponent
      },
      {
        path: 'add-patient/:id',
        component: PatientInfoComponent
      },
      {
        path: 'general_info',
        component: GeneralInfoComponent
      },
      {
        path: 'general_info/:id',
        component: GeneralInfoComponent
      },
      {
        path: 'add_event/:id',
        component: AddEventComponent
      },
      {
        path: 'edit_event/:eventId',
        component: AddEventComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
