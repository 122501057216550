import { Component, OnInit } from "@angular/core";
import {
  Bio,
  Diets,
  LabTest,
  LabTestPackages,
  LabTestProcess,
  LabTestsTaken,
  Languages,
  Occupation,
  Patient,
  RelationTypes,
  Religions,
  TestStatusLog,
} from "src/app/_interfaces/patient";
import { PatientService } from "src/app/_services/patient.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ReqId } from "src/app/_interfaces/reqId";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EventService } from "src/app/_services/event.service";
import { LabService } from "src/app/_services/lab.service";
import { stringify } from "@angular/compiler/src/util";
import { url } from "inspector";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ReligionComponent } from "src/app/super-admin/religion/religion.component";
import { type } from "os";
import { RecipientService } from "src/app/_services/recipient.service";
import { Recipient } from "src/app/_interfaces/register";
import { Countries } from "src/app/constants/countries";

let Occupations: string[] = [
  "UNEMPLOYED",
  "UNSKILLED",

  "SEMISKILLED",
  "SKILLED",
  "SHOP",
  "SEMIPROFESSIONAL",
  "PROFESSIONAL",
  "UNKNOWN",
];

let Menstruation: string[] = [
  "PRE MENOPAUSE",
  "PERI MENOPAUSE",
  "POST MENOPAUSE",
  "SURGICAL AMENORRHEA",
];

let MenstruationType: string[] = ["Regular", "Irregular"];



//

let diets: string[] = [
  "Vegeterian",
  "Vegan",
  "Non - vegeterian",
  "Ovo - vegeterian / Eggetarian",
];

let relations: string[] = [
  "FATHER",
  "MOTHER",
  "SPOUSE",
  "SON",
  "DAUGHTER",
  "FRIEND",
  "UNKNOWN",
];

let languages: string[] = [
  "ASSAMES",
  "BENGALI",
  "BODO",
  "DOGRI",
  "GUJARAT",
  "HINDI",
  "KANNADA",
  "KASHMIR",
  "KONKANI",
  "MAITHIL",
  "MALAYAL",
  "MANIPUR",
  "MARATHI",
  "NEPALI",
  "ODIA",
  "PUNJABI",
  "SANSKRI",
  "SANTALI",
  "SINDHI",
  "TAMIL",
  "TELUGU",
  "URDU",
];

@Component({
  selector: "app-general-info",
  templateUrl: "./general-info.component.html",
  styleUrls: ["./general-info.component.css"],
})
export class GeneralInfoComponent implements OnInit {
 maritalStatus : string[] = ["Divorced", "Married", "Single", "Unknown", "Unmarried","Widowed"];
 bloodTypes : string[] = ["EDTA", "STRECK"];


  patient = {} as Patient;
  reqId = {} as ReqId;
  saveSpinner = false;

  pid: number;
  statusMsg: string;

  dateofbirth: string = null;

  languages = languages;
  Occupations = Occupations;
  Diets = diets;
  Relations = relations;

  Menstruations = Menstruation;
  countries = new Countries().countries;


  menstruationType = MenstruationType;

  labTests: LabTest[];
  packageLabTests: LabTest[];
  labTestPackages: LabTestPackages[];
  labTestProcess: LabTestProcess[];

  add_labTestsTaken = {} as LabTestsTaken;
  editlabTestsTaken_toggle: boolean;
  editlabTestsTaken_index: number;

  failedRedirect: string;
  ffpeBlockStatus: string;
  //bio : Bio;
  bioFormGroup: FormGroup;

  ffpeBlockNumber: number;
  ffpeBlockValues = [];
  slidesNumber: number;
  slideValues = [];
  bloodNumber: number;
  bloodValues = [];
  plasmaNumber: number;
  plasmaValues = [];

  count: number = 0;
  clickCount(): void {
    this.count++;
    console.log(this.count);
  }

  relationTypes: RelationTypes[];

  religionsB: Religions[];
  languagesB: Languages[];
  dietsB: Diets[];

  occupations: Occupation[];
  recipients : Recipient[];

  constructor(
    private routerAct: ActivatedRoute,
    private patientService: PatientService,
    private eventService: EventService,
    private router: Router,
    private snackBar: MatSnackBar,
    private labService: LabService,
    private fb: FormBuilder,
    private recipientService: RecipientService,
  ) {
    this.bioFormGroup = this.fb.group({
      blood: new FormControl(""),

      name: new FormControl(""),
      isbn: new FormControl(""),
    });
  }

  showSuccess() {
    this.snackBar.open(this.statusMsg, "dismiss", {
      duration: 4000,
      verticalPosition: "top",
    });
  }

  addLabTests: {
    labTests: LabTestsTaken[];
  };

  isLabUser = false;
  isPatientId = false;

  ngOnInit() {


    console.log(this.countries);



    const user = JSON.parse(localStorage.getItem("user"));
    if (user["role"] == "LAB_USER") {
      this.isLabUser = true;
    }

    this.pid = +this.routerAct.snapshot.params["id"];
    console.log("pid", this.pid);
    if (this.pid) {
      this.isPatientId = true;
      this.getPatient();
    }

    this.getRecipients();
    this.getOccupations();
    this.getPatientRelations();
    this.getLanguages();
    this.getReligions();
    this.getDiets();

    this.getLabTests();
    this.getLabTestPackages();
    // this.getLabTestsStatus();
    this.getAllTestProcess();

    this.addLabTests = {
      labTests: [],
    };
  }

  userTestStatus = [
    { id: 0, name: "PROCESSING" },
    { id: 1, name: "PASS" },
    { id: 2, name: "FAILED" },
    { id: 3, name: "CONTINUED" },
  ];

  statusTest: string;
  testStatus: string;
  testProcessId: number;
  testProcess: string;
  testTaken: Date;

  packageName: string;

  addLabTest() {
    console.log("selectedTestList", this.selectedTestList);
    if (!this.editlabTestsTaken_toggle) {
      if (this.count > 1) {
        this.addLabTests.labTests = [];
      }
      console.log("add_labTestsTaken", this.add_labTestsTaken);
      for (let i = 0; i < this.selectedTestList.length; i++) {
        this.add_labTestsTaken.labTestId = this.selectedTestList[i].id;
        this.add_labTestsTaken.labTestName = this.selectedTestList[i].name;
        this.add_labTestsTaken.testStatus = this.testStatus;
        this.add_labTestsTaken.testTaken = this.testTaken;
        this.addLabTests.labTests.push(this.add_labTestsTaken);
        this.add_labTestsTaken = {} as LabTestsTaken;
      }
      // let labTest = this.labTests.filter(labTestObj => labTestObj.name === this.add_labTestsTaken.labTestName);
      // this.add_labTestsTaken.labTestId = labTest[0].id;
      // let labTestStatus = this.labTestStatus.filter(labTestStatusObj => labTestStatusObj.name === this.add_labTestsTaken.labTestStatusName);
      // this.add_labTestsTaken.labTestStatusId = labTestStatus[0].id;
      // this.addLabTests.labTests.push(this.add_labTestsTaken);
      // this.add_labTestsTaken = {} as LabTestsTaken;
    } else {
      let labTest = this.labTests.filter(
        (labTestObj) => labTestObj.name === this.add_labTestsTaken.labTestName
      );
      this.add_labTestsTaken.labTestId = labTest[0].id;
      let labTestStatus = this.labTestProcess.filter(
        (labTestStatusObj) =>
          labTestStatusObj.name === this.add_labTestsTaken.labTestStatusName
      );
      this.add_labTestsTaken.labTestStatusId = labTestStatus[0].id;
      this.addLabTests.labTests[this.editlabTestsTaken_index] =
        this.add_labTestsTaken;
      this.editlabTestsTaken_toggle = false;
      this.add_labTestsTaken = {} as LabTestsTaken;
    }
    this.patient.labTestsTaken = this.addLabTests.labTests;
    console.log(this.patient.labTestsTaken);
  }

  editLabTest(edit_labTest: LabTestsTaken, index: number) {
    console.log(index, edit_labTest);
    this.getLabTestProcess(edit_labTest.labTestId);
    this.editlabTestsTaken_index = index;
    this.editlabTestsTaken_toggle = true;
    this.add_labTestsTaken = { ...edit_labTest };
  }

  getLabTestProcess(testId) {
    this.labService
      .getTestProcessesByLabTestId(testId)
      .subscribe((response) => {
        this.labTestProcess = response["payLoad"];
        console.log(this.labTestProcess);
      });
  }

  getAllTestProcess() {
    this.labService.getAllTestProcess().subscribe((response) => {
      this.labTestProcess = response["payLoad"];
      console.log(this.labTestProcess);
    });
  }

  deleteLabTest(index) {
    console.log(index);
    this.addLabTests.labTests.splice(index, 1);
    this.patient.labTestsTaken = this.addLabTests.labTests;
  }

  onSubmit() {
    console.log(this.patient);

    if (this.pid) {
      console.log("patien edit", this.patient);


      this.patient.pid = this.pid;
      this.saveSpinner = true;
      this.patientService.updatePatient(this.patient).subscribe(
        (data) => {
          console.log("data", data);
          this.saveSpinner = false;
          this.statusMsg = data["message"];
          this.showSuccess();
          this.router.navigate(["/patients"]);
        },
        (error) => {
          console.log("error", error);
          this.saveSpinner = false;
          this.statusMsg = error.error["message"];
          this.showSuccess();
          this.router.navigate(["/patients"]);
        }
      );
    } else {


      console.log("New patient req", this.patient);

      this.saveSpinner = true;

      this.patientService.addPatient(this.patient).subscribe(
        (data) => {
          console.log("patient data", data);
          this.saveSpinner = false;
          this.statusMsg = data["message"];
          this.showSuccess();
          this.router.navigate(["/patients"]);
        },
        (error) => {
          console.log("error", error);
          this.saveSpinner = false;
          this.statusMsg = error.error["message"];
          this.showSuccess();
          this.router.navigate(["/patients"]);
        }
      );
    }
  }

  getPatient() {
    console.log("calling getPatient");

    this.reqId.id = this.pid;
    this.patientService.getPatient(this.reqId).subscribe((response) => {
      console.log("patient info", response);

      this.patient = response["payLoad"];
      if(response['bio'] != null){
        this.patient.ffpeBlockValues = response['bio']['ffpeBlockValues'];
      }
      if (this.patient.patientRelation != null) {
        this.patient.patientRelationId = this.patient.patientRelation.id;
      }
      if (this.patient.recipient != null) {
        this.patient.recipientId = this.patient.recipient.id;
      }
      this.patient.labTestsTaken = response["object"];
      this.addLabTests.labTests = this.patient.labTestsTaken;
    });
  }

  getPatientRelations() {
    this.patientService.getPatientRelations().subscribe((response) => {
      this.relationTypes = response["payLoad"];
      console.log("relationTypes", this.relationTypes);
    });
  }

  getLanguages() {
    this.patientService.getLanguages().subscribe((response) => {
      this.languagesB = response["payLoad"];
      console.log("languages", this.languagesB);
    });
  }

  getReligions() {
    this.patientService.getReligion().subscribe((response) => {
      this.religionsB = response["payLoad"];
      console.log("religions", this.religionsB);
    });
  }

  getDiets() {
    this.patientService.getDiets().subscribe((response) => {
      this.dietsB = response["payLoad"];
      console.log("Diets ", this.dietsB);
    });
  }

  getRecipients(){
    this.recipientService.getRecipients().subscribe(
      response => {
        this.recipients = response['payLoad'];
        console.log(this.recipients);
      });
  }

  getOccupations() {
    this.patientService.getOccupation().subscribe((response) => {
      this.occupations = response["payLoad"];
      console.log("occupations", this.occupations);
    });
  }

  getLabTests() {
    this.labService.getLabTests().subscribe((response) => {
      this.labTests = response["payLoad"];
      // console.log(this.labTests);
    });

    this.testDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  selectedTestList = [];
  refferalDoctorNames = [];
  refferalDoctorEmails = [];


  testDropdownSettings = {};

  onItemSelect(item: any) {
    console.log(item);


  }

  getLabTestPackages() {
    this.labService.getLabTestPackages().subscribe((response) => {
      this.labTestPackages = response["payLoad"];
      // console.log("test packages ==> ",this.labTestPackages);
    });
  }

  deleteTest(index: number) {}

  editTest(index: number) {}

  afterSelectPackage = false;
  selectedTest = false;

  getPackageTests(packageName) {
    this.afterSelectPackage = true;

    let testPackage = this.labTestPackages.filter(
      (testPackageObj) => testPackageObj.name === this.packageName
    );
    this.packageLabTests = testPackage[0]["labTests"];
    this.selectedTestList = this.packageLabTests;
    console.log("packageObj ==> ", testPackage);
    console.log("packageLabTests ==> ", this.packageLabTests);
  }

  updateStatus() {
    this.add_labTestsTaken.patientId = this.pid;
    console.log(this.add_labTestsTaken);

    this.labService
      .changeLabTestStatus(this.add_labTestsTaken)
      .subscribe((response) => {
        console.log(response);
        this.statusMsg = response["message"];
        this.showSuccess();
        this.reload();
      });
  }

  testStatusLogs: TestStatusLog[];

  getTestStatusLogs(edit_labTest: LabTestsTaken, index: number) {
    this.add_labTestsTaken = { ...edit_labTest };

    this.labService
      .getTestStatusLog(this.add_labTestsTaken.labTestTakenId)
      .subscribe((response) => {
        this.testStatusLogs = response["payLoad"];
        console.log("test logs", this.testStatusLogs);
      });
  }

  // getLabTestsStatus() {
  //   this.labService.getLabTestStatus().subscribe(
  //     response => {
  //       this.labTestProcess = response['payLoad'];
  //       // console.log(this.labTestStatus);
  //     }
  //   );
  // }

  getFfpeBlockValues(string, index) {
    this.ffpeBlockValues[index] = string;
    console.log("--->", this.ffpeBlockValues);
  }

  getSlidesValues(string, index) {
    this.slideValues[index] = string;
    console.log("--->", this.slideValues);
  }

  getBloodValues(string, index) {
    this.bloodValues[index] = string;
    console.log("--->", this.bloodValues);
  }

  getPlasmaValues(string, index) {
    this.plasmaValues[index] = string;
    console.log("--->", this.plasmaValues);
  }

  addBioBank() {
    this.patient.ffpeBlock = this.ffpeBlockNumber;
    this.patient.slides = this.slidesNumber;
    this.patient.blood = this.bloodNumber;
    this.patient.plasma = this.plasmaNumber;
    this.patient.ffpeBlockValues = this.ffpeBlockValues;
    this.patient.slidesValues = this.slideValues;
    this.patient.bloodValues = this.bloodValues;
    this.patient.plasmaValues = this.plasmaValues;
    console.log(this.patient);
  }

  public getFfpeNumber(str: number): void {
    console.log(str);
    this.ffpeBlockNumber = str;
  }

  public getSlidesNumber(num: number): void {
    console.log(num);
    this.slidesNumber = num;
  }

  public getBloodNumber(str: number): void {
    console.log(str);
    this.bloodNumber = str;
  }

  public getPlasmaNumber(str: number): void {
    console.log(str);
    this.plasmaNumber = str;
  }

  arrayOne(n: number): any[] {
    if (n == null) {
      return Array(0);
    } else {
      return Array(n);
    }
  }

  closeModal() {
    this.add_labTestsTaken = {} as LabTestsTaken;
  }

  reload() {
    console.log("reload", this.pid);
    this.router
      .navigateByUrl("/general_info", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/general_info/", this.pid]);
      });
  }

  isChecked: boolean = false;

  onChange(isChecked: boolean) {
    this.isChecked = isChecked;
    console.log(this.isChecked);

    if (this.isChecked) {
      if (!this.patient.dateOfBirth) {
        this.calculateDOB(this.patient.age);
        console.log("estimated DOB " + this.patient.dateOfBirth);
      }
    } else {
      if (!this.patient.dateOfBirth) {
        //  this.patient.age = this.calculateAge(this.dob);
        console.log("estimated DOB " + this.patient.age);
      }
    }
  }

  calculateAge(birthday: Date) {
    var date = new Date(birthday);
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs);
    this.patient.age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  onChangeAge() {
    console.log(this.patient.age);
    this.patient.dateOfBirth = null;
    if (this.patient.age) this.calculateDOB(this.patient.age);
  }

  calculateDOB(ageYears: number) {
    var ageYears = ageYears;
    var ageMonths = 5;
    var ageDays = 18;
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var dob = new Date(year - ageYears, month - ageMonths, day - ageDays);
    this.patient.dateOfBirth = dob;
    this.dateofbirth = dob.toLocaleDateString();
  }

  onchangeDOB() {
    console.log(this.patient.dateOfBirth);
    this.patient.age = null;
    if (this.patient.dateOfBirth) this.calculateAge(this.patient.dateOfBirth);
  }

  onChangeBMI() {
    if (this.patient.weight > 0 && this.patient.height > 0) {
      // this.patient.bmi =
      //   (this.patient.weight / this.patient.height / this.patient.height) *
      //   10000;

        this.patient.bmi = this.roundTo((this.patient.weight / this.patient.height / this.patient.height) * 10000,1)
    }
  }

  roundTo(num: number, places: number): number {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  bioBankCloseModal() {}
}
