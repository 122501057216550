import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PaginationReqInterface } from "../_interfaces/pagination";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  constructor(private httpClient: HttpClient) {}

  addPatient(body) {
    return this.httpClient.post("/patient/add_patient", body);
  }

  updatePatient(body) {
    return this.httpClient.post("/patient/update_patient", body);
  }

  getPatient(body) {
    return this.httpClient.post("/patient/get", body);
  }

  getPatientList(paginationReqInterface: PaginationReqInterface) {
    return this.httpClient.post(
      "/patient/patient_list",
      paginationReqInterface
    );
  }

  exportToPDF(id) {
    return this.httpClient.get("/patient/" + id + "/export/pdf", {
      responseType: "blob",
    });
  }

  exportToExcel(id): Observable<Blob> {
    return this.httpClient.get("/" + id + "/download/excel/patient_details", {
      responseType: "blob",
    });
  }

  getOccupation() {
    return this.httpClient.get("/patient/occupations");
  }



  getLanguages() {
    return this.httpClient.get("/patient/languages");
  }
  getDiets() {
    return this.httpClient.get("/patient/diets");
  }
  getReligion() {
    return this.httpClient.get("/patient/religions");
  }
  getPatientRelations() {
    return this.httpClient.get("/patient/get_relation_types");
  }


  getLateralities(){
    return this.httpClient.get("/laterality/getLaterality");
  }
}
