import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './_guard/admin.guard';
import { AuthGuard } from './_guard/auth.guard';
import { LabGuard } from './_guard/lab.guard';
import { UserGuard } from './_guard/user.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AdminGuard],
    loadChildren: './pages/pages.module#PagesModule'
  },
  {
    path: 'lab',
    canActivate:[LabGuard],
    loadChildren:  './lab/lab.module#LabModule'
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'super_admin',
    loadChildren: './super-admin/super-admin.module#SuperAdminModule'
  },
  {
    path: 'diagnostic',
    loadChildren : () => import('./diagnostic/diagnostic.module').then(m => m.DiagnosticModule), // new dynamic import method
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
