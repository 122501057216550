import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TobaccoService {

  constructor(public httpClient : HttpClient) { }


  deleteTobacco(body){
    return this.httpClient.post("/tobacco/deleteTobacco", body);
  }

  addOrUpdateTobacco(body){
    return this.httpClient.post("/tobacco/addOrUpdateTobacco", body);
  }

  getTobaccos(){
    return this.httpClient.get("/tobacco/getTobaccos");

  }


}
