import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const user = JSON.parse(localStorage.getItem('user'));
    if(user === null){
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    }
    else if (user['role'] === 'USER' || user['role'] === 'ADMIN' || user['role'] === 'SUPER_ADMIN') {
      return true;
    }  else {
      localStorage.clear();
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
    }

  }

}
