import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MedicalService {
  constructor(private httpClient: HttpClient) {}

  addMedicalInfo(body) {
    return this.httpClient.post("/medical/add_medical", body);
  }

  updateMedicalInfo(body) {
    return this.httpClient.post("/medical/update_medical", body);
  }

  getHospitals() {
    return this.httpClient.get("/medical/get_hospitals");
  }

  getHospitalLocations(hospitalId) {
    return this.httpClient.get(
      "/medical/" + hospitalId + "/get_hospital_locations"
    );
  }

  getHospitalLocation(hospitalLocationId) {
    return this.httpClient.get(
      "/medical/" + hospitalLocationId + "/get_hospital_location"
    );
  }

  getCancerSites() {
    return this.httpClient.get("/medical/cancer_sites");
  }

  getCancerTypes(body) {
    return this.httpClient.post("/medical/cancer_types", body);
  }

  getCancerMorphologyTypes(body) {
    return this.httpClient.post("/medical/morphologyTypes", body);
  }

  getCancerSubTypes(body) {
    return this.httpClient.post("/medical/cancer_sub_types", body);
  }


  getLateralities() {
    return this.httpClient.get("/laterality/getLaterality");
  }

  getCancerCellTypes() {
    return this.httpClient.get("/medical/cancer_cell_types");
  }



  getDiseases() {
    return this.httpClient.get("/medical/diseases");
  }
}
